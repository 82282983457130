


























































































import { getAllLessonsForUser } from "@/api/lesson-service";
import { listMyTeams } from "@/api/team-service";
import { getAllTeamSessionsForUser } from "@/api/team-session-service";
import Learnlink from "@learnlink/interfaces";
import Vue, { PropType } from "vue";
import { CalendarEvent } from "vuetify";

const colors = ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1", "red", "teal", "purple", "pink"];

export default Vue.extend({
  name: "SellerCalendar",
  props: {
    sellerUID: {
      type: String as PropType<string>,
      required: true,
    }
  },
  data() {
    return {
      currentTeam: {} as Learnlink.Team.Team,
      events: [] as CalendarEvent[],
      focus: "",
      lessons: [] as Learnlink.Lesson.default[],
      loading: false,
      sessions: [] as Learnlink.TeamSession.default[],
      selectedOpen: false,
      teams: [] as Learnlink.Team.Team[],
      teamCardVisible: false,
      type: "month",
      weekdays: [1, 2, 3, 4, 5, 6, 0],
    };
  },
  async mounted(): Promise<void> {
    this.loading = true;
    this.sessions = await getAllTeamSessionsForUser(this.sellerUID);
    this.teams = await listMyTeams(this.sellerUID);
    this.lessons = await getAllLessonsForUser(this.sellerUID, "seller");
    this.fetchEvents();
    this.loading = false;
  },
  methods: {
    fetchEvents(): void {
      this.events = this.sessions.map((date) => {
        return {
          name: this.teams.filter(team => team.ID === date.teamID)[0]?.title,
          start: date.startTime * 1000,
          end: date.endTime * 1000,
          timed: true,
          color: date.teamID[1] ? colors[date.teamID[1].charCodeAt(0) % 12] : "blue",
          ID: date.ID,
        } as CalendarEvent;
      }).concat(
        this.lessons.map((lesson) => {
          return {
            name: lesson.customerUID,
            start: lesson.startTime * 1000,
            end: lesson.endTime * 1000,
            timed: true,
            color: lesson.customerUID[1] ? colors[lesson.customerUID[1].charCodeAt(0) % 12] : "blue",
            ID: lesson.ID,
          } as CalendarEvent;
        }));
    },
    viewDay({ date }: Record<"date", string>) {
      this.focus = date;
      this.type = "day";
    },
  },
});
