













import DocumentPropTextAreaEditor from "@/components/projects/ProjectDialog/DocumentPropTextAreaEditor.vue";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "UserNote",
  components: { DocumentPropTextAreaEditor },
  props: {
    note: {
      type: String as PropType<string>,
      default: "",
    },
    user: {
      type: Object as PropType<Learnlink.User.default>,
      default: () => ({}),
    },
  },
});
