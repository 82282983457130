












































































import { computed, defineComponent, PropType } from "@vue/composition-api";
import { copyToClipboard } from "@/helpers/copyToClipboard";
import { levelSymbols } from "@/helpers/stringUtils";
import { weekNumberFromTimestamp } from "@/utils/timeUtils";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";

export default defineComponent({
  name: "AttendanceAndLevel",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const now = Moment().unix();
    const passedSessions = computed(() => props.team.teamSessions?.filter(ts => ts.startTime < now) || []);

    function getLevelSymbol(level: Learnlink.Type.Level): string {
      return level === "high"
        ? levelSymbols[2]
        : level === "medium"
          ? levelSymbols[1]
          : levelSymbols[0];
    }

    return {
      copyToClipboard,
      getLevelSymbol,
      passedSessions,
      weekNumberFromTimestamp,
    };
  },
});
