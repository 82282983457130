import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/teamSessions";

export async function createTeamSession(session: Learnlink.TeamSession.Create): Promise<Learnlink.TeamSession.default> {
  const teamSessionRes = await axios.post<Learnlink.TeamSession.default>(
    `${ENDPOINT}/`,
    session
  );
  return teamSessionRes.data;
}

export async function createAllTeamSessions(session: Learnlink.TeamSession.Create): Promise<void> {
  const teamSessionRes = await axios.post<void>(
    `${ENDPOINT}/all`,
    session
  );
  return teamSessionRes.data;
}

export async function getTeamSessionsForTeam(teamID: string): Promise<Learnlink.TeamSession.default[]> {
  const teamSessionRes = await axios.get<Learnlink.TeamSession.default[]>(
    `${ENDPOINT}/team/${teamID}`
  );

  return teamSessionRes.data;
}

export async function getAllTeamSessionsForUser(uid: string): Promise<Learnlink.TeamSession.default[]> {
  const teamSessionRes = await axios.get<Learnlink.TeamSession.default[]>(
    `${ENDPOINT}/user/${uid}`
  );
  return teamSessionRes.data;
}

export async function updateTeamSession(session: Learnlink.TeamSession.Update): Promise<Learnlink.TeamSession.default> {
  const teamSessionRes = await axios.put<Learnlink.TeamSession.default>(
    `${ENDPOINT}/${session.ID}`,
    session
  );
  return teamSessionRes.data;
}

export async function changeAllTeamSessionTimes(teamID: string, startTimeDifference: number, endTimeDifference: number): Promise<Learnlink.TeamSession.default[]> {
  const teamSessionRes = await axios.put<Learnlink.TeamSession.default[]>(
    `${ENDPOINT}/updateAllTeamSessionTimes/${teamID}`, {
      startTimeDifference,
      endTimeDifference
    }
  );
  return teamSessionRes.data;
}

export async function deleteTeamSession(ID: string): Promise<Learnlink.TeamSession.default> {
  const teamSessionRes = await axios.delete<Learnlink.TeamSession.default>(
    `${ENDPOINT}/delete/${ID}`,
  );
  return teamSessionRes.data;
}
