



























































































import RemoveParentFromTeamDialog from "@/components/teams/RemoveParentFromTeamDialog.vue";
import { capitalize } from "@/helpers/stringUtils";
import { copyToClipboard } from "@/helpers/copyToClipboard";
import { defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";
import { updateUser } from "@/api/user-service";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";
import SellerCard from "@/components/sellers/SellerCard.vue";
import store from "@/store/store";

export default defineComponent({
  name: "TeamUserList",
  components: { RemoveParentFromTeamDialog, SellerCard },
  props: {
    type: {
      type: String as PropType<Learnlink.Type.Role>,
      required: true,
    },
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    }
  },
  setup(props) {
    const vm = reactive({
      currentUID: "",
      previousTeamSession: {} as Learnlink.TeamSession.default,
      removeParentFromTeamDialogVisible: false,
      userDialogVisible: false,
    });

    const openEditCustomerDialog = inject<(customerUID: string) => void>("openEditCustomerDialog");
    const removeUser = inject<(userType: Learnlink.Type.Role, customerUID: string) => void>("removeUserFromTeam");

    onMounted(() => {
      const latestSessions = props.team.teamSessions?.filter(session => {
        const now = Moment().unix();
        const sessionTime = Moment.unix(session.startTime).startOf("day").unix();
        return sessionTime <= now;
      }) || [];

      vm.previousTeamSession = latestSessions[latestSessions.length - 1] || (props.team.teamSessions && props.team.teamSessions[0]);
    });

    async function toggleBetaTester(uid: string, isBetaTester: boolean) {
      try {
        const updateObject: Learnlink.User.Update = {
          uid: uid,
          betaTester: isBetaTester
        };
        await updateUser(updateObject);
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Oppdatert beta-tester-status"
        });
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt - kunne ikke oppdatere beta-tester"
        });
      }
    }

    function getStudentBirthYear(parentUID: string): string {
      const parent = props.team.verboseParents?.filter((parent) => parent.uid === parentUID)[0];
      return parent?.studentBirthYear ? `Født i ${parent.studentBirthYear}` : "";
    }

    function getStudentsForParent(parentUID: string): string {
      const student = props.team.verboseStudents?.filter((student) => student.parentUID === parentUID)[0];
      return student ? `Foresatt til ${student.firstName}` : "";
    }

    function remove(uid: string) {
      if (props.type === "parent") {
        vm.currentUID = uid;
        vm.removeParentFromTeamDialogVisible = true;
      }
      else {
        removeUser && removeUser(props.type, uid);
      }
    }

    function getBackgroundClass(level: number): string {
      const levelColors = {
        1: "#FFE0E0",
        2: "FFFFdd",
        3: "#D0FFD0",
      } as {[key: number]: string};
      return levelColors[level];
    }

    function openEditUserDialog(uid: string, type: string) {
      if (type === "seller") {
        vm.currentUID = uid;
        vm.userDialogVisible = true;
      }
      else openEditCustomerDialog && openEditCustomerDialog(uid);
    }

    return {
      capitalize,
      copyToClipboard,
      getBackgroundClass,
      getStudentBirthYear,
      getStudentsForParent,
      openEditUserDialog,
      remove,
      toggleBetaTester,
      vm
    };
  },
});
