


















































import { createAllTeamSessions } from "@/api/team-session-service";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import store from "@/store/store";
import { stringTimeToUnix } from "@/utils/timeUtils";
import { defineComponent, inject, PropType, reactive, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "CreateAllTeamSessionsDialog",
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    teamID: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      date: "",
      durations: [
        "1 time",
        "1,5 time",
        "2 timer",
        "2,5 timer",
        "3 timer",
        "4 timer",
        "5 timer",
        "6 timer"
      ],
      loading: false,
      selectedDuration: "1,5 time",
      selectedTime: "16:00",
      times: [
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
      ],
    });

    const mountTeam = inject("mount") as () => void;

    async function createAll() {
      vm.loading = true;
      try {
        const startTime = stringTimeToUnix(vm.date, vm.selectedTime);
        const durationHours = +vm.selectedDuration.split(" ")[0].replace(",", ".");
        const durationMinutes = durationHours * 60;
        await createAllTeamSessions({
          endTime: Moment.unix(startTime).add(durationMinutes, "minutes").unix(),
          startTime,
          teamID: props.teamID
        });
        store.dispatch("displayAlert", {
          title: "",
          message: "Oppdatert!",
          color: "success",
        });
        emit("success");
        mountTeam && mountTeam();
        _value.value = false;
      }
      catch (e) {
        handleError(e);
        store.dispatch("displayAlert", {
          title: "",
          message: "Noe gikk galt!",
          color: "error",
        });
      }
      vm.loading = false;
    }

    return {
      _value,
      createAll,
      vm,
    };
  },
});
