import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VExpansionPanels,{attrs:{"flat":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(_vm.comingLessons.length)+" fremtidige timer ")]),_c(VExpansionPanelContent,_vm._l((_vm.comingLessons),function(lesson){return _c(VRow,{key:lesson.ID},[_c(VCol,{staticClass:"text-right",attrs:{"cols":"12","sm":"7"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$moment.unix(lesson.startTime).format('DD/MM HH:mm'))+" - "+_vm._s(_vm.$moment.unix(lesson.endTime).format('HH:mm ([Uke] W)'))+" ")])]),_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VBtn,{attrs:{"color":"danger","x-small":""},on:{"click":function($event){return _vm.cancel(lesson.ID)}}},[_vm._v(" Kanseller ")]),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"warning","x-small":""},on:{"click":function($event){return _vm.update(lesson)}}},[_vm._v(" Flytt ")])],1)],1)}),1)],1),_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(_vm.pastLessons.length)+" fullførte timer ")]),_c(VExpansionPanelContent,_vm._l((_vm.pastLessons),function(lesson){return _c(VRow,{key:lesson.ID},[_c(VCol,{staticClass:"text-right",attrs:{"cols":"12","sm":"7"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$moment.unix(lesson.startTime).format('DD/MM HH:mm'))+" - "+_vm._s(_vm.$moment.unix(lesson.endTime).format('HH:mm ([Uke] W)'))+" ")])]),_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"color":"error","x-small":""},on:{"click":function($event){return _vm.cancel(lesson.ID)}}},on),[_vm._v(" Kanseller ")])]}}],null,true)},[_vm._v(" Husk at saldo må justeres manuelt 😊 ")]),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"warning","x-small":"","disabled":lesson.subtracted > 0},on:{"click":function($event){return _vm.update(lesson)}}},[_vm._v(" Flytt ")])],1)],1)}),1)],1)],1),(_vm.updateLessonDialogVisible)?_c('update-lesson-dialog',{attrs:{"lesson":_vm.currentLesson},on:{"updated":_vm.mount},model:{value:(_vm.updateLessonDialogVisible),callback:function ($$v) {_vm.updateLessonDialogVisible=$$v},expression:"updateLessonDialogVisible"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }