












































































import { updateProfile } from "@/api/profile-service";
import FileList from "@/components/FileList.vue";
import handleError, { sanitizeError } from "@/helpers/errors";
import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "DocumentApproval",
  components: { FileList },
  props: {
    seller: {
      type: Object as PropType<Learnlink.Seller.default>,
      required: true,
    }
  },
  setup(props) {
    const vm = reactive({
      approvedCertificate: false,
      approvedPoliceRecord: false,
      dialogVisible: false,
      loading: false,
      showFileType: "" as Learnlink.File.DocumentCollectionRef,
    });

    onMounted(() => {
      vm.approvedCertificate = props.seller.profile.certificateApproved;
      vm.approvedPoliceRecord = props.seller.profile.policeRecordApproved;
    });

    function openFiles(type: Learnlink.File.DocumentCollectionRef) {
      vm.showFileType = type;
      vm.dialogVisible = true;
    }

    async function setApproval(val: boolean, fileType: "policeRecord" | "certificate") {
      try {
        await updateProfile({
          uid: props.seller.user.uid,
          [fileType + "Approved"]: val,
        });
        await store.commit("displayAlert", {
          color: "success",
          message: "Oppdatert",
        });
      }
      catch (e) {
        handleError(e);
        await store.commit("displayAlert", {
          color: "error",
          message: sanitizeError(e).message,
        });
      }
    }

    return {
      openFiles,
      setApproval,
      vm,
    };
  },
});
