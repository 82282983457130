import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm._value,"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"width":"400"},on:{"click:outside":function($event){_vm._value = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm._value = false}}},[_c(VCard,{staticClass:"pa-6 text-center"},[_c('div',{staticClass:"mt-n4",staticStyle:{"position":"absolute","right":"10px"}},[_c(VBtn,{attrs:{"icon":"","x-large":""},on:{"click":function($event){_vm._value = false}}},[_c(VIcon,[_vm._v("mdi-close-circle")])],1)],1),_c('h3',{staticClass:"mt-4 mb-8"},[_vm._v(" Å churne eller ikke churne, det er spørsmålet 💁💀 ")]),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"error","disabled":_vm.vm.loading,"loading":_vm.vm.loading},on:{"click":function($event){return _vm.churn()}}},[_vm._v(" Churn ")])],1),_c(VCol,[_c(VBtn,{attrs:{"color":"success","disabled":_vm.vm.loading,"loading":_vm.vm.loading},on:{"click":function($event){return _vm.remove()}}},[_vm._v(" Fjern for team-bytte ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }