import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/balances";

export async function createTransaction(transaction: Learnlink.Transaction.Create): Promise<Learnlink.Balance.default> {
  const transactionRes = await axios.post<Learnlink.Balance.default>(
    `${ENDPOINT}/`,
    transaction,
  );

  return transactionRes.data;
}

export async function getBalance(uid: string): Promise<Learnlink.Balance.default & { unusedMinutes: number }> {
  const transactionRes = await axios.get<Learnlink.Balance.default & { unusedMinutes: number }>(
    `${ENDPOINT}/${uid}`,
  );

  return transactionRes.data;
}

export async function invoiceAllNegativeBalances(): Promise<void> {
  await axios.post<void>(
    `${ENDPOINT}/invoiceAllNegativeBalances`,
  );
}

export async function subtractFromLesson(lessonID: string, employeeInitials: string, employeeUID: string): Promise<Learnlink.Balance.default> {
  const balanceRes = await axios.post<Learnlink.Balance.default>(
    `${ENDPOINT}/subtractFromLesson/${lessonID}`,
    {
      employeeInitials,
      employeeUID,
    });

  return balanceRes.data;
}
