






























































































































































import { copyToClipboard } from "@/helpers/copyToClipboard";
import {
  defineComponent,
  inject,
  onMounted,
  PropType,
  provide,
  reactive,
  ref,
  watchEffect,
} from "@vue/composition-api";
import * as TeamService from "@/api/team-service";
import AttendanceAndLevel from "@/components/teams/AttendanceAndLevel.vue";
import EditTeamCard from "./EditTeamDialog/EditTeamCard.vue";
import EditTeamMembers from "@/components/teams/EditTeamMembers.vue";
import Learnlink from "@learnlink/interfaces";
import OverrideRoomURL from "@/components/teams/OverrideRoomURL.vue";
import TeamInformation from "@/components/teams/TeamInformation.vue";
import TeamSessionList from "./TeamSessionList.vue";

export default defineComponent({
  name: "TeamDialog",
  components: { AttendanceAndLevel, EditTeamMembers, OverrideRoomURL, TeamInformation, EditTeamCard, TeamSessionList },
  props: {
    teamID: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      loading: false,
      originalTeamTime: "",
      previousTeam: {} as Learnlink.Team.View.FullMetadata,
      team: {} as Learnlink.Team.View.FullMetadata,
    });

    const openTeamDialog = inject<(teamID: string) => void>("openTeamDialog");

    onMounted(async () => {
      await mount();
    });

    function close() {
      _value.value = false;
      emit("close");
    }

    async function mount() {
      vm.loading = true;
      vm.team = await TeamService.readTeam(props.teamID);

      if (vm.team.previousTeamID) {
        vm.previousTeam = await TeamService.readTeam(vm.team.previousTeamID);
      }

      vm.loading = false;
    }

    provide("mount", mount);

    return {
      _value,
      close,
      copyToClipboard,
      mount,
      openTeamDialog,
      vm,
    };
  },
});
