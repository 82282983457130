




























































































































import { updateAccount } from "@/api/account-service";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import store from "@/store/store";
import Vue, { PropType } from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "EditCredentials",
  props: {
    account: {
      type: Object as PropType<Learnlink.Account.default>,
      required: true
    }
  },
  data() {
    return {
      description: "Logg inn på Minecraft Education Edition med følgende konto:",
      loading: false,
      password: "",
      title: "Minecraft Education Edition",
      url: "https://education.minecraft.net/en-us/get-started/download",
      username: "",
    };
  },
  mounted() {
    if (this.account.credentials?.length) {
      this.password = this.account.credentials[0].password;
      this.username = this.account.credentials[0].username;
    }
  },
  methods: {
    async deleteCredential(credential: Learnlink.Credential.Credential) {
      this.loading = true;

      try {
        const updateObject = {
          credentials: this.account.credentials.filter((c) => c !== credential),
          uid: this.account.uid
        };

        await updateAccount(updateObject);

        await store.dispatch("displayAlert", {
          color: "success",
          message: "Lagret!",
        });

        this.$emit("success");
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt!",
        });
      }

      this.loading = false;
    },
    async submit() {
      this.loading = true;

      try {
        const updateObject = {
          credentials: this.account.credentials || [],
          uid: this.account.uid
        };

        updateObject.credentials.push({
          created: Moment().unix(),
          description: this.description,
          password: this.password,
          title: this.title,
          type: "minecraft",
          url: this.url,
          username: this.username
        });

        await updateAccount(updateObject);

        await store.dispatch("displayAlert", {
          color: "success",
          message: "Lagret!",
        });

        this.$emit("success");
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt!",
        });
      }

      this.loading = false;
    }
  }
});
