











import { updatePhotoApproved } from "@/api/profile-service";
import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "ProfilePhotoApprovalSwitch",
  props: {
    seller: {
      type: Object as PropType<Learnlink.Seller.default>,
      required: true,
    }
  },
  setup(props) {
    const vm = reactive({
      photoApproved: false,
    });

    onMounted(() => {
      vm.photoApproved = !!props.seller.profile.photoApproved;
    });

    async function setProfilePhotoApproved(val: boolean) {
      try {
        await updatePhotoApproved(props.seller.user.uid, val);
        store.commit("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });
      }
      catch (e) {
        store.commit("displayAlert", {
          color: "red",
          message: "Kunne ikke oppdatere",
        });
      }
    }

    return {
      setProfilePhotoApproved,
      vm,
    };
  }
});
