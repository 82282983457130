















































import { sanitizeError } from "@/helpers/errors";
import { search } from "@/api/customer-service";
import CustomerList from "@/components/core/CustomerList.vue";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";
import StudentListThumbnail from "@/components/customers/StudentListThumbnail.vue";
import Vue from "vue";

export default Vue.extend({
  name: "SearchCustomers",
  components: { StudentListThumbnail, CustomerList },
  data() {
    return {
      loading: false,
      customers: [] as Learnlink.Customer.default[],
      students: [] as Learnlink.Student.View.Admin.Thumbnail[],
      query: "",
    };
  },
  methods: {
    async search() {
      this.loading = true;
      this.customers = [];
      this.students = [];

      try {
        if (this.query.includes(" ")) this.query = this.query.replace(/\s/g, "");
        if (this.query.includes("+47")) this.query = this.query.slice(3);
        const searchResults = await search(this.query);

        this.customers = searchResults.customers;
        this.students = searchResults.students;

        if (!this.customers.length) {
          store.commit("displayAlert", {
            color: "red",
            message: "Fant ingen kunder. (For søk etter Pro/PL, gå til LS-søk)",
          });
        }
      }
      catch (e) {
        store.commit("displayAlert", {
          color: "red",
          message: sanitizeError(e).message,
        });
      }

      this.loading = false;
    },
  },
});
