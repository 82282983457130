












































































































import { cancelLesson, getAllLessonsForProject } from "@/api/lesson-service";
import UpdateLessonDialog from "@/components/sellers/SellerCard/UpdateLessonDialog.vue";
import moment from "@/services/moment";
import { PropType } from "@vue/composition-api";
import Vue from "vue";
import store from "@/store/store";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "ProjectLessonList",
  components: { UpdateLessonDialog },
  props: {
    projectID: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data() {
    return {
      currentLesson: {} as Learnlink.Lesson.FullViewAdmin,
      comingLessons: [] as Learnlink.Lesson.FullViewAdmin[],
      pastLessons: [] as Learnlink.Lesson.FullViewAdmin[],
      updateLessonDialogVisible: false,
    };
  },
  async mounted() {
    await this.mount();
  },
  methods: {
    async cancel(lessonID: string): Promise<void> {
      try {
        await cancelLesson(lessonID);
        await store.dispatch("displayAlert", {
          color: "green",
          message: "Time kansellert",
        });
        await this.mount();
      }
      catch (e) {
        handleError(e);
      }
    },
    async mount(): Promise<void> {
      this.comingLessons = [];
      this.pastLessons = [];

      const now = moment().unix();
      const lessons = await getAllLessonsForProject(this.projectID);

      lessons.forEach(lesson => {
        if (lesson.startTime > now) {
          this.comingLessons.push(lesson);
        }
        else {
          this.pastLessons.push(lesson);
        }
      });
    },
    update(lesson: Learnlink.Lesson.FullViewAdmin): void {
      this.currentLesson = lesson;
      this.updateLessonDialogVisible = true;
    },
  },
});
