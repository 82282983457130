























































import { axios } from "@/configuration";
import handleError, { sanitizeError } from "@/helpers/errors";
import store from "@/store/store";
import Vue, { PropType } from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "CreateProject",
  props: {
    fields: {
      type: Array as PropType<Learnlink.DocumentField.Create[]>,
      required: true
    },
    route: {
      type: String as PropType<string>,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      response: {},
      request: {} as Record<string, unknown>,
    };
  },
  methods: {
    async createDocument() {
      try {
        this.loading = true;

        Object.values(this.fields).forEach(field => {
          if (field.val !== null && field.val !== "") {
            this.request[field.title] = field.val;
          }
        });

        const response = await axios.post(this.route, this.request);
        this.response = response.data;

        await store.dispatch("displayAlert", {
          color: "green",
          message: "Suksess!",
        });
      }
      catch (e) {
        handleError(e);
        if (sanitizeError(e).code === 409) {
          await store.dispatch("displayAlert", {
            color: "red",
            message: "E-postadressen er allerede i bruk"
          });
        }
        else {
          await store.dispatch("displayAlert", {
            color: "red",
            message: "Noe gikk galt - kunden ble ikke registrert"
          });
        }
      }

      this.loading = false;
    },
    inputHandler(event: KeyboardEvent) {
      if (event.keyCode === 13) {
        this.createDocument();
      }
    },
  },
});
