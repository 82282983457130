














































import { defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CustomerThumbnail",
  props: {
    customer: {
      type: Object as PropType<Learnlink.Customer.default>,
      required: true
    }
  },
  setup(props) {
    const vm = reactive({
      customerCardVisible: false,
      hasSubscription: false,
    });

    onMounted(() => {
      vm.hasSubscription = props.customer.stripeCustomerAccount?.subscriptions?.data.length > 0;
    });

    const openEditCustomerDialog = inject<(customerUID: string) => void>("openEditCustomerDialog");

    return {
      openEditCustomerDialog,
      vm,
    };
  },
});
