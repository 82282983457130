


















































import Vue, { PropType } from "vue";
import { axios } from "@/configuration";
import handleError from "@/helpers/errors";
import store from "@/store/store";

export default Vue.extend({
  name: "ChangePassword",
  props: {
    uid: {
      type: String as PropType<string>,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      password: "",
      passwordChangeActive: false,
    };
  },
  computed: {
    checkPasswordLength(): boolean {
      return this.password.length < 8;
    },
  },
  methods: {
    async changePassword() {
      this.loading = true;
      try {
        await axios.put("/auth/changePasswordFromAdmin/" + this.uid, {
          uid: this.uid,
          password: this.password
        });
        store.commit("displayAlert", {
          color: "success",
          message: "Oppdatert 🥳",
        });
        this.password = "";
      }
      catch (e) {
        handleError(e);
        store.commit("displayAlert", {
          color: "red",
          message: "Klarte ikke oppdatere passord",
        });
      }
      this.loading = false;
    },
  }
});
