













import { updateEmail } from "@/api/user-service";
import { axios } from "@/configuration";
import { sanitizeError } from "@/helpers/errors";
import store from "@/store/store";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    collection: {
      type: String as PropType<string>,
      required: true,
    },
    doc: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
    id: {
      type: String as PropType<string>,
      required: true,
    },
    field: {
      type: String as PropType<string>,
      required: true,
    },
    label: {
      type: String as PropType<string>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    placeholder: {
      type: String as PropType<string>,
      default: "",
    },
  },
  data() {
    return {
      value: "",
      hint: "",
      updateObject: {} as Record<string, unknown>,
    };
  },
  mounted() {
    // Initialize picker with existing value if it has one.
    this.value = this.doc[this.field] || "";
  },
  methods: {
    async update() {
      this.updateObject = {};
      try {
        if (this.field === "email") {
          if (this.validateEmail()) {
            await updateEmail(this.id, this.value);

            store.dispatch("displayAlert", {
              color: "green",
              message: "Oppdatert",
            });

            // Emitting the entire updated document to make the status-color green.
            this.$emit("success", Object.assign(this.doc, this.updateObject));
          }
          else {
            this.hint = "ugyldig e-postadresse";
          }
        }
        else {
          await this.updateField();

          store.dispatch("displayAlert", {
            color: "green",
            message: "Oppdatert",
          });

          // Emitting the entire updated document to make the status-color green.
          this.$emit("success", Object.assign(this.doc, this.updateObject));
        }
      }
      catch (e) {
        store.dispatch("displayAlert", {
          color: "red",
          message: sanitizeError(e).message,
        });
      }
    },
    updateField() {
      this.updateObject[this.field] = this.value;
      this.updateObject.ID = this.id;
      this.updateObject.uid = this.id;

      return axios.put(`/${this.collection}/${this.id}`, this.updateObject);
    },
    validateEmail() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(this.value);
    },
  },
});
