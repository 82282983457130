import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticStyle:{"text-align":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"10"}},[_c('h4',[_vm._v("Saldo: "+_vm._s(_vm.vm.balance.balance)+" minutter ("+_vm._s(Math.round((_vm.vm.balance.balance / 60) * 10) / 10)+" timer)")]),_c('h5',[_vm._v("Minutter mistet pga. rollover: "+_vm._s(_vm.vm.balance.unusedMinutes)+" ("+_vm._s(Math.round((_vm.vm.balance.unusedMinutes / 60) * 10) / 10)+" timer)")])])],1),_c(VRow,{attrs:{"justify":"center"}},[_c('h5',[_vm._v("Endre saldo")])]),_c(VRow,{staticStyle:{"max-width":"500px","margin":"auto"},attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"5"}},[_c(VTextField,{attrs:{"prefix":_vm.vm.minutes >= 0 ? 'Legg til' : 'Trekk fra',"suffix":"minutter","type":"number"},model:{value:(_vm.vm.minutes),callback:function ($$v) {_vm.$set(_vm.vm, "minutes", $$v)},expression:"vm.minutes"}})],1),_c(VCol,{attrs:{"cols":"7"}},[_c(VTextField,{attrs:{"label":"Beskrivelse","type":"text"},model:{value:(_vm.vm.description),callback:function ($$v) {_vm.$set(_vm.vm, "description", $$v)},expression:"vm.description"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.vm.description || _vm.vm.minutes === 0,"loading":_vm.vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Lagre! ")])],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c('h3',[_vm._v("Tidligere transaksjoner")])]),_c(VRow,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.vm.balance.transactions,"loading":_vm.vm.loading,"no-data-text":"Ingen","sort-by":"created","sort-desc":""},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Moment.unix(item.created).format("LLLL"))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }