import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/teams";

export async function createTeam(team: Learnlink.Team.Create): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.post<Learnlink.Team.Team>(
    `${ENDPOINT}`,
    team,
  );

  return teamRes.data;
}

export async function readTeam(teamID: string): Promise<Learnlink.Team.View.FullMetadata> {
  const teamRes = await axios.get<Learnlink.Team.View.FullMetadata>(
    `${ENDPOINT}/${teamID}`,
  );

  return teamRes.data;
}

export async function updateTeam(team: Learnlink.Team.Update): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.put<Learnlink.Team.Team>(
    `${ENDPOINT}/${team.ID}`,
    team,
  );

  return teamRes.data;
}

export async function deleteTeam(teamID: string): Promise<void> {
  await axios.delete<void>(
    `${ENDPOINT}/${teamID}`,
  );
}

export async function activateTeam(teamID: string): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.put<Learnlink.Team.Team>(
    `${ENDPOINT}/activate/${teamID}`,
  );

  return teamRes.data;
}

export async function completeTeam(teamID: string): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.put<Learnlink.Team.Team>(
    `${ENDPOINT}/complete/${teamID}`,
  );

  return teamRes.data;
}

export async function deactivateTeam(teamID: string): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.put<Learnlink.Team.Team>(
    `${ENDPOINT}/deactivate/${teamID}`,
  );

  return teamRes.data;
}

export async function listMyTeams(uid: string): Promise<Learnlink.Team.Team[]> {
  const teamsRes = await axios.get<Learnlink.Team.Team[]>(
    `${ENDPOINT}/user/${uid}`,
  );

  return teamsRes.data;
}

export async function listTeamsWithAdminThumbnails(): Promise<Learnlink.Team.View.Admin.Thumbnail[]> {
  const teamsRes = await axios.get<Learnlink.Team.View.Admin.Thumbnail[]>(
    `${ENDPOINT}/list/thumbnails`,
  );

  return teamsRes.data;
}

export async function listAvailableTeamsForCourse(courseID: string): Promise<Learnlink.Team.View.Admin.Thumbnail[]> {
  const teamsRes = await axios.get<Learnlink.Team.View.Admin.Thumbnail[]>(
    `${ENDPOINT}/available/${courseID}`,
  );

  return teamsRes.data;
}

export async function addUser(userType: Learnlink.Type.Role, teamID: string, uid: string): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.put<Learnlink.Team.Team>(
    `${ENDPOINT}/add-user/${uid}`, {
      userType,
      teamID,
    },
  );

  return teamRes.data;
}

export async function removeUser(userType: Learnlink.Type.Role, teamID: string, uid: string): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.put<Learnlink.Team.Team>(
    `${ENDPOINT}/remove-user/${uid}`, {
      userType,
      teamID,
    },
  );

  return teamRes.data;
}

export async function churnUser(userType: Learnlink.Type.Role, teamID: string, uid: string): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.put<Learnlink.Team.Team>(
    `${ENDPOINT}/churn-user/${uid}`, {
      userType,
      teamID,
    },
  );

  return teamRes.data;
}

export async function postInfo(teamID: string, info: Learnlink.TeamInformation.Create): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.post<Learnlink.Team.Team>(
    `${ENDPOINT}/${teamID}/info`,
    info,
  );

  return teamRes.data;
}

export async function postInfoToList(teamIDs: string[], info: Learnlink.TeamInformation.Create): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.post<Learnlink.Team.Team>(
    `${ENDPOINT}/infoToList`,
    { info, teamIDs },
  );

  return teamRes.data;
}

export async function postOverrideRoomURL(teamID: string, URL: string, username: string, password: string): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.put<Learnlink.Team.Team>(
    `${ENDPOINT}/${teamID}/overrideRoomURL`, {
      password,
      url: URL,
      username,
    },
  );

  return teamRes.data;
}

export async function createDiplomasForTeam(teamID: string): Promise<void> {
  await axios.post<void>(
    `${ENDPOINT}/diploma/${teamID}`
  );
}

export async function deleteInfo(teamID: string, infoID: string): Promise<Learnlink.Team.Team> {
  const teamRes = await axios.delete<Learnlink.Team.Team>(
    `${ENDPOINT}/${teamID}/info/${infoID}`,
  );

  return teamRes.data;
}
