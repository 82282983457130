import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VDataIterator,{attrs:{"items-per-page":1000,"items":_vm.vm.customers,"loading":_vm.vm.loading,"no-data-text":_vm.vm.mounted ? 'Ingen kunder funnet.' : 'Søk for å laste inn kunder.',"loading-text":"Laster inn kunder..."},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(VToolbar,{staticClass:"py-2 mb-4",attrs:{"flat":""}},[[_c(VSpacer),_c(VBtn,{attrs:{"dark":"","color":"primary","loading":_vm.vm.loading},on:{"click":function($event){return _vm.search()}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)]],2)]},proxy:true},{key:"default",fn:function(props){return [_c(VRow,_vm._l((props.items),function(item){return _c(VCol,{key:item.user.uid,attrs:{"cols":"12","sm":"4","lg":"3","xl":"2"}},[_c('customer-thumbnail',{attrs:{"customer":item}})],1)}),1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }