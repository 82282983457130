




































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */

import { copyToClipboard } from "@/helpers/copyToClipboard";
import { getCustomer, invoiceNegativeBalance, transferNegativeBalanceToInvoiceItem } from "@/api/customer-service";
import { Weekdays } from "@/utils/timeUtils";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import BalanceAdjustmentDialog from "../BalanceAdjustmentDialog.vue";
import ChangePassword from "@/components/customers/ChangePassword.vue";
import EditCustomerForm from "./EditCustomerForm.vue";
import EditStudentForm from "@/components/customers/EditStudentForm.vue";
import handleError from "@/helpers/errors";
import IntercomComments from "../core/IntercomComments.vue";
import Learnlink from "@learnlink/interfaces";
import SendSMS from "../SendSMS.vue";
import store from "@/store/store";
import StudentThumbnail from "@/components/customers/StudentThumbnail.vue";
import Syncers from "../Syncers.vue";
import UserNote from "../UserNote.vue";
import Moment from "@/services/moment";

export default defineComponent({
  name: "EditCustomerCard",
  components: {
    BalanceAdjustmentDialog,
    ChangePassword,
    EditCustomerForm,
    EditStudentForm,
    IntercomComments,
    SendSMS,
    StudentThumbnail,
    Syncers,
    UserNote,
  },
  props: {
    uid: {
      type: String as PropType<string>,
      required: true,
    },
    teams: {
      type: Array as PropType<Learnlink.Team.Team[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      customer: {} as Learnlink.Customer.default,
      loading: true,
    });

    function close() {
      emit("close");
    }

    function getReadableWeekdays(weekdays: Learnlink.Type.Weekday[]): string {
      let days = "";
      let count = 0;
      for (const weekday of weekdays) {
        days += Weekdays[weekday];
        count += 1;
        if (count < weekdays.length) {
          days += ", ";
        }
      }
      return days;
    }

    function invoice(customer: Learnlink.Customer.default) {
      invoiceNegativeBalance(customer, store.state.profile);
    }

    function transfer(customer: Learnlink.Customer.default) {
      transferNegativeBalanceToInvoiceItem(customer, store.state.profile);
    }

    async function mount() {
      vm.loading = true;
      try {
        vm.customer = await getCustomer(props.uid);
      }
      catch (e: any) {
        handleError(e);
        emit("close");
        if (e.response?.data?.error?.code === 400) {
          await store.dispatch("displayAlert", {
            title: "Deltager-konto",
            message: "Kan ikke åpne deltager-kontoer her ennå.",
            color: "red",
          });
        }
      }
      vm.loading = false;
    }

    onMounted(async () => {
      await mount();
    });

    return {
      close,
      copyToClipboard,
      getReadableWeekdays,
      invoice,
      Moment,
      mount,
      transfer,
      vm,
    };
  },
});
