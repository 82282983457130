







































































































import { copyToClipboard } from "@/helpers/copyToClipboard";
import { deleteStudent } from "@/api/auth-service";
import { getStudent } from "@/api/customer-service";
import Learnlink from "@learnlink/interfaces";
import ChangePassword from "./ChangePassword.vue";
import EditCredentials from "./EditCredentials.vue";
import EditStudentForm from "@/components/customers/EditStudentForm.vue";
import handleError from "@/helpers/errors";
import store from "@/store/store";
import UserNote from "../UserNote.vue";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "StudentThumbnail",
  components: { EditStudentForm, UserNote, EditCredentials, ChangePassword },
  props: {
    studentUID: {
      type: String as PropType<string>,
      required: true,
    },
    teams: {
      type: Array as PropType<Learnlink.Team.Team[]>,
      required: true,
    },
  },
  data() {
    return {
      copyToClipboard,
      deleting: false,
      listAndCreateStudentsDialogVisible: false,
      loading: true,
      student: {},
    };
  },
  async mounted() {
    try {
      this.student = await getStudent(this.studentUID);
    }
    catch (e) {
      handleError(e);
    }

    this.loading = false;
  },
  methods: {
    async deleteStudent() {
      this.deleting = true;
      try {
        await deleteStudent(this.studentUID);
        await store.dispatch("displayAlert", {
          title: "🥳",
          message: "Eleven ble slettet.",
          color: "success",
        });
        this.$emit("success");
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          title: "😭",
          message: "Det skjedde noe galt.",
          color: "error",
        });
        handleError(e);
      }
      this.deleting = false;
    },
  },
});
