import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"align":"center","hide-details":""}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VSwitch,{attrs:{"inset":"","label":"Godkjent vitnemål","color":"success","dense":"","hide-details":""},on:{"change":function($event){return _vm.setApproval(_vm.vm.approvedCertificate, 'certificate')}},model:{value:(_vm.vm.approvedCertificate),callback:function ($$v) {_vm.$set(_vm.vm, "approvedCertificate", $$v)},expression:"vm.approvedCertificate"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openFiles('certificates')}}},[_vm._v(" Åpne ")])],1),_c(VCol,{attrs:{"cols":"8"}},[_c(VSwitch,{attrs:{"inset":"","label":"Godkjent politiattest","color":"success","dense":"","hide-details":""},on:{"change":function($event){return _vm.setApproval(_vm.vm.approvedPoliceRecord, 'policeRecord')}},model:{value:(_vm.vm.approvedPoliceRecord),callback:function ($$v) {_vm.$set(_vm.vm, "approvedPoliceRecord", $$v)},expression:"vm.approvedPoliceRecord"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.openFiles('policeRecords')}}},[_vm._v(" Åpne ")])],1)],1),_c(VDialog,{attrs:{"fullscreen":""},model:{value:(_vm.vm.dialogVisible),callback:function ($$v) {_vm.$set(_vm.vm, "dialogVisible", $$v)},expression:"vm.dialogVisible"}},[_c(VCard,[_c(VBtn,{attrs:{"fab":"","fixed":"","right":"","depressed":"","color":"white"},on:{"click":function($event){_vm.vm.dialogVisible = false}}},[_c(VIcon,[_vm._v("mdi-close-circle")])],1),_c('file-list',{key:_vm.vm.showFileType,attrs:{"seller":_vm.seller,"type":_vm.vm.showFileType}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }