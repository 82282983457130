import CustomError from "@/classes/CustomError";
import Alert from "@/types/Alert";
import * as Sentry from "@sentry/vue";
import { AxiosError } from "axios";

interface LearnlinkError extends Error {
  code?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export default function handleError(error: any): void {
  // eslint-disable-next-line no-console
  console.error(sanitizeError(error));
  // If it's an error instance we can pass it directly to Sentry
  if (error instanceof Error) {
    Sentry.withScope(scope => {
      if (axiosError(error)) {
        if (error.response?.data && error.response?.headers) {
          // The maximum limit for the event payload is 200KB.
          if (error.response?.headers["content-length"] < 2 * 10 ** 5) {
            scope.setExtra("Extra data", error.response?.data);
          }
          else {
            scope.setExtra("Extra data", "The response data exceeded 200KB, and is therefore not shown here. Sentry's maximum limit for the event payload is 200KB");
          }
        }
      }
      else {
        scope.setExtra("Extra data", "No extra data");
      }
      Sentry.captureException(sanitizeError(error));
    });
  }
  else {
    Sentry.captureException(new CustomError(error));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function sanitizeError(error: any): LearnlinkError {
  return error.response?.data?.error || error;
}

export function getAuthErrorNotification(authErrorCode: string): Alert {
  if (authErrorCode === "auth/wrong-password") {
    return {
      title: "Feil passord",
      message: "",
      type: "error",
    };
  }
  else if (authErrorCode === "auth/user-not-found") {
    return {
      title: "Fant ikke brukeren",
      message: "",
      type: "error",
    };
  }
  else if (authErrorCode === "auth/invalid-email") {
    return {
      title: "Ugyldig e-postadresse",
      message: "",
      type: "error",
    };
  }
  else if (authErrorCode === "auth/network-request-failed") {
    return {
      title: "Fant ikke nettet",
      message: "Det ser ut til at internett-tilkoblingen din er borte. Det kan hjelpe å starte nettleseren på nytt.",
      type: "error",
    };
  }
  return {
    title: "Ukjent feil",
    message: "Kontakt support hvis problemet vedvarer.",
    type: "error",
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function axiosError(object: any): object is AxiosError {
  return "response" in object;
}
