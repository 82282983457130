





































import { listFiles } from "@/api/file-service";
import useAsyncData from "@/helpers/useAsyncData";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, PropType } from "@vue/composition-api";
export default defineComponent({
  name: "FileList",
  props: {
    seller: {
      type: Object as PropType<Learnlink.Seller.default>,
      required: true,
    },
    type: {
      type: String as PropType<Learnlink.File.DocumentCollectionRef>,
      required: true,
    },
  },
  setup(props) {
    const { isLoading, data: files } = useAsyncData(() => listFiles(props.type, props.seller.user.uid));

    return {
      files,
      isLoading,
    };
  },
});
