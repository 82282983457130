import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):(_vm.vm.projects.length)?_c(VExpansionPanels,{attrs:{"flat":""}},_vm._l((_vm.vm.projects),function(project){return _c(VExpansionPanel,{key:project.ID},[_c(VExpansionPanelHeader,[_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c('strong',[_vm._v(_vm._s(project.customerUID.substring(0, 3).toUpperCase()))])]),(project.customer && project.customer.profile)?_c(VCol,{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(project.customer.profile.firstName)+" "+_vm._s(project.customer.profile.lastName)+" ")]):_vm._e(),(project.readableCategories && project.readableCategories.length)?_c(VCol,{attrs:{"cols":"4"}},_vm._l((project.readableCategories),function(category){return _c('span',{key:category},[_vm._v(" "+_vm._s(category)),_c('br')])}),0):_vm._e(),_c(VCol,[_vm._v(" "+_vm._s(_vm.getProjectLifecycleStatus(project))+" ")])],1)],1),_c(VExpansionPanelContent,[_c('project-lesson-list',{attrs:{"project-i-d":project.ID}})],1)],1)}),1):_c(VAlert,{attrs:{"type":"info","text":"","shaped":""}},[_vm._v(" Ingen elever ennå. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }