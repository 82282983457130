import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/courses";

export async function createCourse(course: Learnlink.Course.Create): Promise<Learnlink.Course.default> {
  const courseRes = await axios.post<Learnlink.Course.default>(
    `${ENDPOINT}`,
    course,
  );

  return courseRes.data;
}

export async function updateCourse(course: Learnlink.Course.Update): Promise<Learnlink.Course.default> {
  const courseRes = await axios.put<Learnlink.Course.default>(
    `${ENDPOINT}`,
    course,
  );

  return courseRes.data;
}

export async function listCourses(): Promise<Learnlink.Course.default[]> {
  const courseRes = await axios.get<Learnlink.Course.default[]>(
    `${ENDPOINT}`,
  );

  return courseRes.data;
}
