



































import { getUserNotes } from "@/api/intercom-service";
import Learnlink from "@learnlink/interfaces";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "IntercomComments",
  props: {
    email: {
      type: String as PropType<string>,
      required: true
    }
  },
  data() {
    return {
      comments: [] as Learnlink.Intercom.Note[],
      loading: true,
      moment: Moment
    };
  },
  async mounted() {
    try {
      this.comments = await getUserNotes(this.email);
    }
    catch (e) {
      handleError(e);
    }
    this.loading = false;
  }
});
