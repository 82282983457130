import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('small',[_c('b',[_vm._v("TeamSession ID:")]),_vm._v(" "+_vm._s(_vm.session.ID))])]),_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Dato","readonly":"","prepend-icon":"mdi-calendar"},model:{value:(_vm.vm.date),callback:function ($$v) {_vm.$set(_vm.vm, "date", $$v)},expression:"vm.date"}},'v-text-field',attrs,false),on))]}}])},[_c(VDatePicker,{attrs:{"first-day-of-week":1},model:{value:(_vm.vm.date),callback:function ($$v) {_vm.$set(_vm.vm, "date", $$v)},expression:"vm.date"}})],1)],1),_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Fra klokken","readonly":"","prepend-icon":"mdi-clock-time-four-outline"},model:{value:(_vm.vm.startTime),callback:function ($$v) {_vm.$set(_vm.vm, "startTime", $$v)},expression:"vm.startTime"}},'v-text-field',attrs,false),on))]}}])},[_c(VTimePicker,{attrs:{"allowed-minutes":_vm.allowedStartTimeMinutes,"format":"24hr","id":"start-time"},model:{value:(_vm.vm.startTime),callback:function ($$v) {_vm.$set(_vm.vm, "startTime", $$v)},expression:"vm.startTime"}})],1)],1),_c(VCol,{attrs:{"cols":"12","lg":"4"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Til klokken","readonly":"","prepend-icon":"mdi-clock-time-four-outline"},model:{value:(_vm.vm.endTime),callback:function ($$v) {_vm.$set(_vm.vm, "endTime", $$v)},expression:"vm.endTime"}},'v-text-field',attrs,false),on))]}}])},[_c(VTimePicker,{attrs:{"allowed-minutes":_vm.allowedEndTimeMinutes,"allowed-hours":_vm.allowedEndTimeHours,"format":"24hr","id":"end-time"},model:{value:(_vm.vm.endTime),callback:function ($$v) {_vm.$set(_vm.vm, "endTime", $$v)},expression:"vm.endTime"}})],1)],1)],1),_c(VTextarea,{attrs:{"label":"Automatisk SMS","hint":"Meldingen som blir sent ut etter en PRO har levert inn session rapport. Meldingen må være minst 10 bokstaver lang ellers blir den ikke sendt.","outlined":"","auto-grow":"","rows":"1","row-height":"15"},model:{value:(_vm.vm.automaticSMS),callback:function ($$v) {_vm.$set(_vm.vm, "automaticSMS", $$v)},expression:"vm.automaticSMS"}}),_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"mr-2 mb-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteSession()}}},[_vm._v(" Slett session ")]),_c(VBtn,{staticClass:"mb-2",attrs:{"color":"primary","small":"","loading":_vm.vm.loading},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Oppdater! ")]),_c(VBtn,{staticClass:"ml-2 mb-2",attrs:{"color":"primary","small":"","loading":_vm.vm.loading},on:{"click":function($event){return _vm.updateAllSessionTimes()}}},[_vm._v(" Oppdater alle! ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }