






























































import CreateCustomer from "@/components/customers/CreateCustomer.vue";
import CustomerList from "@/components/core/CustomerList.vue";
import SearchCustomers from "@/components/customers/SearchCustomers.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Customers",
  components: { CustomerList, SearchCustomers, CreateCustomer },
  metaInfo() {
    return {
      title: "Admin / Kunder",
    };
  },
});
