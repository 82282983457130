import firebase from "firebase/app";
import "firebase/performance";
import "firebase/auth";
import "firebase/storage";

import environment from "@/config/environment";

firebase.initializeApp(environment.firebase);
firebase.performance();

const auth = firebase.auth();
const storage = firebase.storage();

export { auth, storage };
