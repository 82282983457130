import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/platform-settings";

export async function getOnboardingSettings(): Promise<Learnlink.PlatformSettings.Onboarding> {
  const res = await axios.get<Learnlink.PlatformSettings.Onboarding>(
    `${ENDPOINT}/onboarding`
  );
  return res.data;
}

export async function getTeamSettings(): Promise<Learnlink.PlatformSettings.Teams> {
  const res = await axios.get<Learnlink.PlatformSettings.Teams>(
    `${ENDPOINT}/teams`
  );
  return res.data;
}

export async function updateOnboardingSettings(settings: Learnlink.PlatformSettings.Onboarding):
Promise<Learnlink.PlatformSettings.Onboarding> {
  const res = await axios.put<Learnlink.PlatformSettings.Onboarding>(
    `${ENDPOINT}/onboarding`,
    settings
  );
  return res.data;
}

export async function addPlatformLocation(location: Learnlink.PlatformSettings.Location): Promise<void> {
  await axios.post(`${ENDPOINT}/teams/location`, location);
}
export async function removePlatformlocation(id: string): Promise<void> {
  await axios.delete(`${ENDPOINT}/teams/location/${id}`);
}
export async function migrateLocations(props: {
  old: string,
  new: string
}): Promise<void> {
  await axios.post(`${ENDPOINT}/teams/migrate-location`, {
    from: props.old,
    to: props.new,
  });
}
