

















































import ApplicationApprovalSwitch from "@/components/sellers/SellerCard/switches/ApplicationApprovalSwitch.vue";
import InterviewApprovalSwitch from "@/components/sellers/SellerCard/switches/InterviewApprovalSwitch.vue";
import ProfilePhotoApprovalSwitch from "./switches/ProfilePhotoApprovalSwitch.vue";
import ProSwitch from "@/components/sellers/SellerCard/switches/ProSwitch.vue";
import SellerBlockedSwitch from "./switches/SellerBlockedSwitch.vue";
import TrainingApprovalSwitch from "@/components/sellers/SellerCard/switches/TrainingApprovalSwitch.vue";
import { defineComponent, PropType } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  components: {
    ApplicationApprovalSwitch,
    InterviewApprovalSwitch,
    ProfilePhotoApprovalSwitch,
    ProSwitch,
    SellerBlockedSwitch,
    TrainingApprovalSwitch
  },
  name: "SellerApproval",
  props: {
    seller: {
      type: Object as PropType<Learnlink.Seller.default>,
      required: true,
    }
  },
});
