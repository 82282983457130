import Learnlink from "@learnlink/interfaces";

export function getProjectLifecycleStatus(project: Learnlink.Project.default): string {
  let status = "Venter godkjenning";

  if (project.approved > 0) {
    status = "Venter lærer";
  }
  if (project.closed > 0) {
    status = "Aktivt";
  }
  if (project.completed > 0) {
    status = "Fullført";
  }
  if (project.cancelled > 0) {
    status = "Kansellert";
  }
  return status;
}

export function getProjectType(project: Learnlink.Project.FullViewAdmin): string {
  return project.winback ? "Winback" : project.previousProjectID ? "PL-bytte" : "Vanlig oppdrag";
}
