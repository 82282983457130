















import SellerCalendar from "@/components/sellers/SellerCard/SellerCalendar.vue";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "SellerCalendarDialog",
  components: { SellerCalendar },
  props: {
    sellerUID: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      propValue: this.value,
    };
  },
  watch: {
    propValue: function() {
      this.$emit("input", this.propValue);
    },
  },
});
