














































import { getCustomers } from "@/api/customer-service";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import CustomerThumbnail from "../customers/CustomerThumbnail.vue";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "CustomerList",
  components: {
    CustomerThumbnail,
  },
  props: {
    path: {
      type: String as PropType<string>,
      default: "",
    },
    list: {
      type: Array as PropType<Learnlink.Customer.default[]>,
      default: () => [],
    },
  },
  setup(props) {
    const vm = reactive({
      activeCustomer: {},
      balanceAdjustmentDialogVisible: false,
      customers: [] as Learnlink.Customer.default[],
      loading: false,
      mounted: false,
    });

    onMounted(() => {
      if (props.list && props.list.length) {
        vm.customers = props.list;
        vm.mounted = true;
      }
    });

    async function search() {
      vm.mounted = true;
      vm.loading = true;

      try {
        vm.customers = await getCustomers(props.path);
      }
      catch (e) {
        handleError(e);
        vm.customers = [];
      }

      vm.loading = false;
    }

    return {
      search,
      vm,
    };
  },
});
