





















































































import { deleteInfo, postInfo } from "@/api/team-service";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import store from "@/store/store";
import Vue from "vue";

export default Vue.extend({
  name: "TeamInformation",
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      text: "",
    };
  },
  methods: {
    async deleteInformation(infoID: string) {
      this.loading = true;
      try {
        await deleteInfo(this.team.ID, infoID);
        this.$emit("success");
      }
      catch (e) {
        handleError(e);
        store.dispatch("displayAlert", {
          color: "red",
          message: "Noe er feil - informasjonen ble ikke slettet",
        });
      }
      this.loading = false;
    },
    getByline(timestamp: number, uid: string) {
      const author = this.team.verboseSellers?.filter((poster: Record<string, unknown>) => poster.uid === uid)[0];
      if (author) {
        return `${author.firstName} ${author.lastName}, ${Moment.unix(timestamp).format("Do MMM [Kl.] HH:mm")}`;
      }
      else {
        return `Ansatt-UID ${uid}, ${Moment.unix(timestamp).format("Do MMM [Kl.] HH:mm")}`;
      }
    },
    async submit() {
      this.loading = true;
      try {
        await postInfo(this.team.ID, {
          author: store.state.user.uid,
          text: this.text,
        });
        store.dispatch("displayAlert", {
          color: "green",
          message: "Meldinger sendt!"
        });
      }
      catch (e) {
        handleError(e);
        store.dispatch("displayAlert", {
          color: "red",
          message: "Noe er feil - informasjonen ble ikke sendt",
        });
      }
      this.$emit("success");
      this.loading = false;
    }
  },
});
