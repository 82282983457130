












import { axios } from "@/configuration";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "DocumentFieldPicker",
  props: {
    alternatives: {
      type: Array as PropType<{value: string, text: string}[]>,
      default: [],
    },
    collection: {
      type: String as PropType<string>,
      required: true,
    },
    doc: {
      type: Object as PropType<Learnlink.Project.FullViewAdmin | Learnlink.User.default | Learnlink.Account.default | Learnlink.Profile.default>,
      required: true,
    },
    field: {
      type: String as PropType<string>,
      required: true,
    },
    id: {
      type: String as PropType<string>,
      required: true,
    },
    label: {
      type: String as PropType<string>,
      required: true,
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    }
  },
  setup(props) {
    const picked = ref("");

    onMounted(() => {
      picked.value = props.doc[props.field as keyof (Learnlink.Project.FullViewAdmin | Learnlink.User.default | Learnlink.Account.default | Learnlink.Profile.default)] || "";
    });

    async function updateField() {
      const updateObject = props.collection === "projects"
        ? {
          ID: props.id,
          [props.field]: picked.value
        } as Learnlink.Project.Update
        : {
          uid: props.id,
          [props.field]: picked.value
        } as Learnlink.User.Update | Learnlink.Account.Update | Learnlink.Profile.Update;

      const collection = props.collection === "accounts" ? props.collection : "admin/" + props.collection;

      await axios.put(`/${collection}/${props.id}`, updateObject);

      await store.dispatch("displayAlert", {
        color: "green",
        message: "Oppdatert",
      });
    }

    return {
      picked,
      updateField,
    };
  },
});
