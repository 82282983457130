import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}}),_c(VCol,{attrs:{"cols":"8"}},[_c(VRow,_vm._l((_vm.passedSessions),function(session){return _c(VCol,{key:session.ID,attrs:{"cols":"1"}},[_c('small',[_c('strong',[_vm._v("Uke "+_vm._s(_vm.weekNumberFromTimestamp(session.startTime)))])])])}),1)],1)],1),_vm._l((_vm.team.verboseStudents),function(student){return _c(VRow,{key:student.uid},[_c(VCol,{staticClass:"text-left",attrs:{"cols":"4"}},[_c('small',[_c('strong',[_vm._v(_vm._s(student.firstName)+" "+_vm._s(student.lastName))])]),_c('br'),_c('small',{on:{"click":function($event){return _vm.copyToClipboard(student.uid)}}},[_vm._v(" "+_vm._s(student.uid)+" ")])]),_c(VCol,{attrs:{"cols":"8"}},[_c(VRow,_vm._l((_vm.passedSessions),function(session){return _c(VCol,{key:session.ID,attrs:{"cols":"1"}},[(session.noShows && session.noShows.includes(student.uid))?_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" mdi-close-circle ")]):(session.performance && session.performance[student.uid])?_c('div',[_c(VTooltip,{attrs:{"disabled":!session.performance[student.uid].comment,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":session.performance[student.uid].comment ? 'lime' : 'success'}},on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(session.performance[student.uid].comment)+" ")])]),_c('h4',{staticClass:"ml-4 mt-n4"},[_vm._v(" "+_vm._s(_vm.getLevelSymbol(session.performance[student.uid].level))+" ")])],1):_c(VIcon,{attrs:{"color":"warning"}},[_vm._v(" mdi-help-circle ")])],1)}),1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }