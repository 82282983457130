
















































































import { defineComponent, inject, onMounted, PropType, provide, reactive } from "@vue/composition-api";
import { createTeamSession, getTeamSessionsForTeam } from "@/api/team-session-service";
import { weekNumberFromTimestamp } from "@/utils/timeUtils";
import createAllTeamSessionsDialog from "./CreateAllTeamSessionsDialog.vue";
import EditTeamSession from "./EditTeamSession.vue";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";

export default defineComponent({
  components: { EditTeamSession, createAllTeamSessionsDialog },
  name: "TeamSessionList",
  props: {
    teamID: {
      type: String as PropType<string>,
      required: true
    },
  },
  setup(props) {
    const vm = reactive({
      createAllTeamSessionsDialogVisible: false,
      date: "",
      loading: false,
      activeTeamSessions: [] as Learnlink.TeamSession.default[],
      deletedTeamSessions: [] as Learnlink.TeamSession.default[],
    });
    const mountTeam = inject("mount") as () => void;

    onMounted(async () => {
      vm.loading = true;
      await mount();
      vm.loading = false;
    });

    async function create() {
      vm.loading = true;

      if (vm.activeTeamSessions.length) {
        const latestSession = vm.activeTeamSessions[vm.activeTeamSessions.length - 1];

        await createTeamSession({
          endTime: Moment.unix(latestSession.endTime).add(1, "week").unix(),
          startTime: Moment.unix(latestSession.startTime).add(1, "week").unix(),
          teamID: props.teamID
        });
        await mount();
        if (mountTeam) mountTeam();
      }
      else {
        vm.createAllTeamSessionsDialogVisible = true;
      }
      vm.loading = false;
    }

    function getReadableWeekNumber(session: Learnlink.TeamSession.default): string {
      return `Uke ${weekNumberFromTimestamp(session.startTime)}`;
    }

    function getReadableTime(session: Learnlink.TeamSession.default): string {
      return `${Moment.unix(session.startTime).format("dddd L HH:mm")} - ${Moment.unix(session.endTime).format("HH:mm")}`;
    }

    async function mount() {
      const allTeamSessions = await getTeamSessionsForTeam(props.teamID);
      vm.activeTeamSessions = allTeamSessions.filter(s => s.deleted === 0);
      vm.deletedTeamSessions = allTeamSessions.filter(s => s.deleted > 0);
    }

    provide("mountTeamSessions", mount);

    function allowedStartTimeMinutes(value: number): boolean {
      return value % 5 === 0;
    }

    return {
      allowedStartTimeMinutes,
      create,
      getReadableTime,
      getReadableWeekNumber,
      mount,
      vm
    };
  },
});
