




























import CreateDocument from "@/components/CreateDocument.vue";
import Vue from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "CreateCustomer",
  components: {
    CreateDocument,
  },
  data() {
    return {
      activeCategoriesArray: [],
      customerFields: [
        {
          title: "firstName",
          alternatives: [],
          multiple: false,
          val: null,
        },
        {
          title: "lastName",
          alternatives: [],
          multiple: false,
          val: null,
        },
        {
          title: "email",
          alternatives: [],
          multiple: false,
          val: null,
        },
        {
          title: "phone",
          alternatives: [],
          multiple: false,
          val: null,
        },
      ] as Learnlink.DocumentField.Create[],
    };
  },
});
