







import store from "@/store/store";
import { defineComponent } from "@vue/composition-api";
import Customers from "./Customers.vue";
import Login from "@/components/Login.vue";

export default defineComponent({
  name: "Home",
  components: {
    Customers,
    Login
  },
  setup() {
    return {
      store,
    };
  }
});
