



















































































































import { updateLesson } from "@/api/lesson-service";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import store from "@/store/store";
import { stringTimeToUnix } from "@/utils/timeUtils";
import Vue, { PropType } from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "UpdateLessonDialog",
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    lesson: {
      type: Object as PropType<Learnlink.Lesson.FullViewAdmin>,
      required: true,
    },
  },
  data() {
    return {
      date: Moment.unix(this.lesson.startTime).format("YYYY-MM-DD"),
      endTime: Moment.unix(this.lesson.endTime).format("HH:mm"),
      loading: true,
      propValue: this.value,
      startTime: Moment.unix(this.lesson.startTime).format("HH:mm"),
      step: 1,
    };
  },
  mounted() {
    this.loading = false;
  },
  watch: {
    propValue: function() {
      this.$emit("input", this.propValue);
    },
  },
  computed: {
    endTimeUnix: function(): number {
      return stringTimeToUnix(this.date, this.endTime);
    },
    startTimeUnix: function(): number {
      return stringTimeToUnix(this.date, this.startTime);
    },
    readableDate: function(): string {
      return Moment(this.date).format("dddd Do MMMM");
    },
  },
  methods: {
    allowedEndTimeHours(value: number) {
      if (this.startTime) return value >= +this.startTime.split(":")[0];
      return value > 0;
    },
    allowedEndTimeMinutes(value: number): boolean {
      return value % 5 === 0;
    },
    allowedStartTimeMinutes(value: number): boolean {
      return value % 5 === 0;
    },
    async update() {
      this.loading = true;
      try {
        await updateLesson({
          ID: this.lesson.ID,
          endTime: this.endTimeUnix,
          startTime: this.startTimeUnix,
        });
        await store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert 🥳",
        });
        this.$emit("updated");
      }
      catch (e) {
        handleError(e);
      }
      this.loading = false;
      this.propValue = false;
    },
    cancel() {
      this.propValue = false;
    },
  }
});
