import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/intercom";

export async function getUserNotes(email: string): Promise<Learnlink.Intercom.Note[]> {
  const notesRes = await axios.get<Learnlink.Intercom.Note[]>(
    `${ENDPOINT}/userNotes/${email}`
  );
  return notesRes.data;
}

export async function getIntercomUserID(uid: string): Promise<string> {
  const notesRes = await axios.get<string>(
    `${ENDPOINT}/userID/${uid}`
  );
  return notesRes.data;
}

export async function syncUserWithIntercom(uid: string): Promise<void> {
  await axios.put<void>(
    `/admin/intercom/updateIntercomStats/${uid}`
  );
}
