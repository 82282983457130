import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/projects";

export async function getAllProjectsForSeller(sellerUID: string): Promise<Learnlink.Project.FullViewAdmin[]> {
  const projectsRes = await axios.get<Learnlink.Project.FullViewAdmin[]>(
    `${ENDPOINT}/allProjectsForSeller/${sellerUID}`,
  );

  return projectsRes.data;
}

export async function getPracticalInfo(): Promise<string> {
  const projectRes = await axios.get<Learnlink.PlatformSettings.PracticalInfo>(
    `${ENDPOINT}/practical-info`,
  );
  return projectRes.data.url;
}

export async function getProject(projectID: string): Promise<Learnlink.Project.FullViewAdmin> {
  const projectRes = await axios.get<Learnlink.Project.FullViewAdmin>(
    `${ENDPOINT}/${projectID}`,
  );

  return projectRes.data;
}

export async function listProjects(path: string): Promise<Learnlink.Project.FullViewAdmin[]> {
  const projectsRes = await axios.get<Learnlink.Project.FullViewAdmin[]>(
    `${ENDPOINT}/${path}`,
  );

  return projectsRes.data;
}

export async function approveProject(projectID: string): Promise<Learnlink.Project.default> {
  const projectRes = await axios.put<Learnlink.Project.default>(
    `${ENDPOINT}/approve/${projectID}`,
  );

  return projectRes.data;
}

export async function cancelProject(projectID: string): Promise<Learnlink.Project.default> {
  const projectRes = await axios.put<Learnlink.Project.default>(
    `${ENDPOINT}/cancel/${projectID}`,
  );

  return projectRes.data;
}

export async function changeSeller(projectID: string, newSellerUID: string, closeOldProject: boolean): Promise<Learnlink.Project.default> {
  const projectRes = await axios.put<Learnlink.Project.default>(
    `${ENDPOINT}/changeSeller/${projectID}/${newSellerUID}/${closeOldProject ? "true" : "false"}`,
  );

  return projectRes.data;
}

export async function closeProject(projectID: string, sellerUID: string): Promise<Learnlink.Project.default> {
  const projectRes = await axios.put<Learnlink.Project.default>(
    `${ENDPOINT}/close/${projectID}/${sellerUID}`,
  );

  return projectRes.data;
}

export async function completeProject(projectID: string): Promise<Learnlink.Project.default> {
  const projectRes = await axios.put<Learnlink.Project.default>(
    `${ENDPOINT}/complete/${projectID}`,
  );

  return projectRes.data;
}

export async function unApproveProject(projectID: string): Promise<Learnlink.Project.default> {
  const projectRes = await axios.put<Learnlink.Project.default>(
    `${ENDPOINT}/unApprove/${projectID}`,
  );

  return projectRes.data;
}

export async function unCancelProject(projectID: string): Promise<Learnlink.Project.default> {
  const projectRes = await axios.put<Learnlink.Project.default>(
    `${ENDPOINT}/unCancel/${projectID}`,
  );

  return projectRes.data;
}

export async function unCompleteProject(projectID: string): Promise<Learnlink.Project.default> {
  const projectRes = await axios.put<Learnlink.Project.default>(
    `${ENDPOINT}/unComplete/${projectID}`,
  );

  return projectRes.data;
}

export async function updateCategories(projectID: string, categories: string[]): Promise<Learnlink.Project.default> {
  const projectRes = await axios.put<Learnlink.Project.default>(
    `${ENDPOINT}/${projectID}/categories`,
    categories,
  );

  return projectRes.data;
}

export async function updatePracticalInfo(url: string): Promise<string> {
  const projectRes = await axios.put<string>(
    `${ENDPOINT}/practical-info`, {
      url
    }
  );

  return projectRes.data;
}

export async function updateWeekdays(projectID: string, weekdays: Learnlink.Type.Weekday[]): Promise<Learnlink.Project.default> {
  const projectRes = await axios.put<Learnlink.Project.default>(
    `${ENDPOINT}/${projectID}/weekdays`,
    weekdays,
  );

  return projectRes.data;
}
