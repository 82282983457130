import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/accounts";

export async function getAccount(uid: string): Promise<Learnlink.Account.default> {
  const accountRes = await axios.get<Learnlink.Account.default>(
    `${ENDPOINT}/${uid}`
  );
  return accountRes.data;
}

export async function updateAccount(account: Learnlink.Account.Update): Promise<Learnlink.Account.default> {
  const accountRes = await axios.put<Learnlink.Account.default>(
    `${ENDPOINT}/${account.uid}`,
    account,
  );
  return accountRes.data;
}
