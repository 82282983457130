import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c('document-prop-text-field-editor',{key:_vm.customer.uid + '/firstName',attrs:{"id":_vm.customer.profile.uid,"collection":'admin/profiles',"doc":_vm.customer.profile,"field":'firstName',"label":'Fornavn'}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('document-prop-text-field-editor',{key:_vm.customer.uid + '/lastName',attrs:{"id":_vm.customer.profile.uid,"collection":'admin/profiles',"doc":_vm.customer.profile,"field":'lastName',"label":'Etternavn'}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('document-prop-text-field-editor',{key:_vm.customer.uid + '/phone',attrs:{"id":_vm.customer.account.uid,"collection":'accounts',"doc":_vm.customer.account,"field":'phone',"label":'Telefonnummer'}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('document-prop-text-field-editor',{key:_vm.customer.uid + '/email',attrs:{"id":_vm.customer.account.uid,"collection":'accounts',"doc":_vm.customer.account,"field":'email',"label":'E-post'}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('document-prop-text-field-editor',{key:_vm.customer.uid + '/postalCode',attrs:{"id":_vm.customer.user.uid,"collection":'users',"doc":_vm.customer.user,"field":'postalCode',"label":'Postnummer'}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('document-prop-text-field-editor',{key:_vm.customer.uid + '/city',attrs:{"id":_vm.customer.account.uid,"collection":'accounts',"doc":_vm.customer.account,"field":'city',"label":'By'}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('document-field-picker',{key:_vm.customer.uid + '/secondaryPerson',attrs:{"id":_vm.customer.account.uid,"collection":'accounts',"doc":_vm.customer.account,"field":'secondaryPerson',"label":'Hvem er sekundær kontaktperson?',"alternatives":[
          {
            value: '',
            text: '(Ikke valgt)'
          },
          {
            value: 'mother',
            text: 'Mor'
          },
          {
            value: 'father',
            text: 'Far'
          },
          {
            value: 'student',
            text: 'Eleven'
          },
          {
            value: 'other',
            text: 'Andre'
          }
        ]}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('document-prop-text-field-editor',{key:_vm.customer.uid + '/secondaryPersonName',attrs:{"id":_vm.customer.account.uid,"collection":'accounts',"doc":_vm.customer.account,"field":'secondaryPersonName',"label":'Navn på sekundær kontaktperson?'}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('document-prop-text-field-editor',{key:_vm.customer.uid + '/secondaryPersonEmail',attrs:{"id":_vm.customer.account.uid,"collection":'accounts',"doc":_vm.customer.account,"field":'secondaryPersonEmail',"label":'Hva er epost til sekundær kontaktperson?'}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('document-prop-text-field-editor',{key:_vm.customer.uid + '/secondaryPersonPhone',attrs:{"id":_vm.customer.account.uid,"collection":'accounts',"doc":_vm.customer.account,"field":'secondaryPersonPhone',"label":'Telefon til sekundær kontaktperson?'}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }