import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/profiles";

export async function getProfile(uid: string): Promise<Learnlink.Profile.default> {
  const profileRes = await axios.get<Learnlink.Profile.default>(
    `${ENDPOINT}/${uid}`
  );
  return profileRes.data;
}

export async function updatePhotoApproved(uid: string, photoApproved: boolean): Promise<Learnlink.Profile.default> {
  const profileRes = await axios.put<Learnlink.Profile.default>(
    `${ENDPOINT}/${uid}/photoApproved`,
    { photoApproved },
  );

  return profileRes.data;
}

export const updateProfile = async(profile: Learnlink.Profile.Update): Promise<Learnlink.Profile.default> => {
  const profileRes = await axios.put<Learnlink.Profile.default>(
    `${ENDPOINT}/${profile.uid}`,
    profile,
  );
  return profileRes.data;
};
