import "@mdi/font/css/materialdesignicons.css";
import { storage } from "@/services/firebase";
import { environment } from "./configuration";
import VueMeta from "vue-meta";
import * as _Sentry from "@sentry/browser";
import App from "./App.vue";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import router from "@/router";
import store from "@/store/store";
import Vue from "vue";
import vuetify from "@/plugins/vuetify";

Vue.config.productionTip = false;

Vue.prototype.$env = environment;
Vue.prototype.$moment = Moment;
Vue.prototype.$sentry = _Sentry;
Vue.prototype.$storage = storage;
Vue.use(VueMeta);

Vue.config.errorHandler = (err: Error) => {
  store.dispatch("displayAlert", {
    title: err.name,
    message: err.message,
    color: "error",
  });
  handleError(err);
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
