import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/users";

export async function getUser(uid: string): Promise<Learnlink.User.default> {
  const userRes = await axios.get<Learnlink.User.default>(
    `${ENDPOINT}/${uid}`
  );
  return userRes.data;
}

export async function updateUser(user: Learnlink.User.Update): Promise<Learnlink.User.default> {
  const usersRes = await axios.put<Learnlink.User.default>(
    `${ENDPOINT}/${user.uid}`,
    user,
  );
  return usersRes.data;
}

export async function updateEmail(uid: string, email: string): Promise<void> {
  await axios.put<void>(
    `${ENDPOINT}/updateEmail/${uid}`,
    { email }
  );
}
