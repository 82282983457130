import { auth } from "@/services/firebase";
import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/sellers",
      name: "sellers",
      component: () => import(/* webpackChunkName: "sellers" */ "./views/Sellers/Sellers.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/teams",
      name: "teams",
      component: () => import(/* webpackChunkName: "teams" */ "./views/Teams.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/lessons",
      name: "lessons",
      component: () => import(/* webpackChunkName: "lessons" */ "./views/Lessons.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/projects",
      name: "projects",
      component: () => import(/* webpackChunkName: "projects" */ "./views/Projects.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/news",
      name: "news",
      component: () => import(/* webpackChunkName: "news" */ "./views/NewsAndFeedback.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/achievements",
      name: "achievements",
      component: () => import(/* webpackChunkName: "achievements" */ "./views/Achievements.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orders",
      name: "orders",
      component: () => import(/* webpackChunkName: "orders" */ "./views/Orders.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !auth.currentUser) {
    next("/");
  }
  else {
    next();
  }
});

export default router;
