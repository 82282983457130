











































































import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import { listMyTeams } from "@/api/team-service";
import EditCustomerCard from "@/components/customers/EditCustomerCard.vue";
import TeamThumbnail from "@/components/teams/TeamThumbnail.vue";
import useAsyncData from "@/helpers/useAsyncData";

export default defineComponent({
  name: "EditCustomerDialog",
  components: { EditCustomerCard, TeamThumbnail },
  props: {
    uid: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const { isLoading, data: myTeams } = useAsyncData(() => listMyTeams(props.uid));

    return {
      _value,
      isLoading,
      myTeams,
    };
  },
});
