/**
 * Use this when an error is caught without the error being the instance of an error.
 */
export default class CustomError extends Error {
  public code: number;

  constructor(error: {message: string, code: number, stack: string}) {
    super(error.message);
    this.code = error.code;
    this.name = "APIError";
    this.message = error.message;
    this.stack = error.stack;
  }
}
