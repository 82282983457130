import Moment from "@/services/moment";
import Learnlink from "@learnlink/interfaces";

export enum Weekdays {
  monday = "Mandager",
  tuesday = "Tirsdager",
  wednesday = "Onsdager",
  thursday = "Torsdager",
  friday = "Fredager",
  saturday = "Lørdager",
  sunday = "Søndager",
}

export enum Decision {
  no = "Har sagt nei",
  yes = "Har sagt ja",
  uncertain = "Vet ikke",
}

export function getClockTimeFromUnix(timestamp: number): string {
  return Moment.unix(timestamp).format("HH:mm");
}

export function getReadableDates(dates: string[]): string {
  return dates.map((date) => {
    return Moment(date).format("dddd Do MMMM");
  }).toString();
}

export function getReadableStartTime(team: Learnlink.Team.View.FullMetadata): string {
  if (team.teamSessions[0]?.startTime) {
    const startTime = team.teamSessions[0].startTime;
    const readableStartTime = Moment.unix(startTime).format("HH:mm");
    return `Oppstart ${weekdayFromTimestamp(startTime)} kl. ${readableStartTime} i uke ${weekNumberFromTimestamp(startTime)}, ${Moment.unix(startTime).format("YYYY")}`;
  }
  return "Ingen datoer satt";
}

export function weekdayFromTimestampEnglish(timestamp: number): Learnlink.Type.Weekday {
  return Moment.unix(timestamp).locale("en").format("dddd").toLowerCase() as Learnlink.Type.Weekday;
}

export function weekdayFromTimestamp(timestamp: number): Learnlink.Type.Weekday {
  return Moment.unix(timestamp).format("dddd").toLowerCase() as Learnlink.Type.Weekday;
}

export function weekNumberFromTimestamp(timestamp: number): string {
  return Moment.unix(timestamp).format("w");
}

export function stringTimeToUnix(date: string, time: string): number {
  const _date = Moment(date).format("YYYY-MM-DD");
  return Moment(`${_date} ${time}`, "YYYY-MM-DD HH:mm").unix();
}
