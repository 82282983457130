import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,_vm._l((_vm.fields),function(field){return _c(VCol,{key:field.title,attrs:{"cols":"4"}},[(field.alternatives.length)?_c('div',[_c(VAutocomplete,{attrs:{"disabled":_vm.loading || field.disabled,"items":field.alternatives,"label":field.title,"multiple":field.multiple,"chips":"","outlined":""},model:{value:(field.val),callback:function ($$v) {_vm.$set(field, "val", $$v)},expression:"field.val"}})],1):_c(VTextField,{attrs:{"disabled":_vm.loading || field.disabled,"label":field.title,"placeholder":field.placeholder,"outlined":""},on:{"keydown":_vm.inputHandler},model:{value:(field.val),callback:function ($$v) {_vm.$set(field, "val", $$v)},expression:"field.val"}})],1)}),1),_c(VRow,{attrs:{"justify":"center"}},[_c(VBtn,{attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.createDocument()}}},[_vm._v(" Opprett! ")])],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,[_c('h5',[_vm._v("Request:")]),_c('pre',[_vm._v(_vm._s(_vm.request))])]),_c(VCol,[_c('h5',[_vm._v("Response:")]),_c('pre',[_vm._v(_vm._s(_vm.response))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }