

































import Learnlink from "@learnlink/interfaces";
import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "SellerCardAboutSeller",
  props: {
    seller: {
      type: Object as PropType<Learnlink.Seller.default>,
      required: true,
    }
  },
  setup(props) {
    const heOrShe = computed(() =>
      props.seller.user.gender === "male"
        ? "ham"
        : props.seller.user.gender === "female"
          ? "henne"
          : "hen"
    );

    return {
      heOrShe,
    };
  }
});
