














import { axios } from "@/configuration";
import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";

export default defineComponent({
  props: {
    collection: {
      type: String as PropType<string>,
      required: true,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    doc: {
      type: Object as PropType<Learnlink.Project.FullViewAdmin | Learnlink.User.default | Learnlink.Account.default | Learnlink.Profile.default>,
      required: true,
    },
    field: {
      type: String as PropType<string>,
      required: true,
    },
    id: {
      type: String as PropType<string>,
      required: true,
    },
    label: {
      type: String as PropType<string>,
      required: true,
    },
    placeholder: {
      type: String as PropType<string>,
      default: "",
    }
  },
  setup(props, { emit }) {
    const vm = reactive({
      hint: "",
      value: "",
    });

    onMounted(() => {
      vm.value = props.doc[props.field as keyof (Learnlink.Project.FullViewAdmin | Learnlink.User.default | Learnlink.Account.default | Learnlink.Profile.default)] || "";
    });

    async function update() {
      if (props.field === "email") {
        if (validateEmail()) {
          vm.hint = "";
          await axios.put("/auth/" + props.id, { email: vm.value });
          await updateField();

          store.dispatch("displayAlert", {
            color: "green",
            message: "Oppdatert",
          });

          emit("success");
        }
        else {
          vm.hint = "ugyldig e-postadresse";
        }
      }
      else {
        await updateField();

        store.dispatch("displayAlert", {
          color: "green",
          message: "Oppdatert",
        });

        emit("success");
      }
    }

    async function updateField() {
      const updateObject = props.collection === "projects"
        ? {
          ID: props.id,
          [props.field]: vm.value
        } as Learnlink.Project.Update
        : {
          uid: props.id,
          [props.field]: vm.value
        } as Learnlink.User.Update | Learnlink.Account.Update | Learnlink.Profile.Update;

      return await axios.put(`/${props.collection}/${props.id}`, updateObject);
    }

    function validateEmail() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(vm.value);
    }

    return {
      update,
      vm,
    };
  },
});
