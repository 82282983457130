import { axios } from "@/configuration";

interface AuthObject {
  email: string;
  password: string;
  uid: string;
}

export async function deleteStudent(uid: string): Promise<AuthObject> {
  const authRes = await axios.delete<AuthObject>(
    `/auth/student/${uid}`,
  );
  return authRes.data;
}
