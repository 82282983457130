




































































import Learnlink from "@learnlink/interfaces";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { createTransaction, getBalance } from "@/api/balance-service";
import store from "@/store/store";
import Moment from "@/services/moment";

export default defineComponent({
  name: "BalanceAdjustmentDialog",
  props: {
    customer: {
      type: Object as PropType<Learnlink.Customer.default>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vm = reactive({
      balance: {} as Learnlink.Balance.default & { unusedMinutes: number },
      description: "",
      loading: false,
      minutes: 0,
    });

    const headers = [
      {
        text: "Tidspunkt",
        value: "created",
      },
      {
        text: "Beskrivelse",
        value: "description",
      },
      {
        text: "Gammel saldo",
        value: "oldBalance",
      },
      {
        text: "Justering",
        value: "minutes",
      },
      {
        text: "Ny saldo",
        value: "newBalance",
      },
      {
        text: "Endret av",
        value: "employeeInitials",
      },
    ];

    onMounted(async() => {
      await mount();
    });

    function close() {
      emit("close");
    }

    async function mount() {
      vm.loading = true;
      vm.minutes = 0;
      vm.description = "";

      vm.balance = await getBalance(props.customer.user.uid);

      vm.loading = false;
    }

    async function submit() {
      vm.loading = true;

      await createTransaction({
        uid: props.customer.user.uid,
        minutes: vm.minutes,
        description: vm.description,
        employeeInitials: store.state.profile.firstName.charAt(0) + store.state.profile.lastName.charAt(0),
        employeeUID: store.state.profile.uid,
      });

      await mount();

      vm.loading = false;
    }

    return {
      close,
      headers,
      Moment,
      mount,
      submit,
      vm,
    };
  }
});
