































































import { getAllProjectsForSeller } from "@/api/project-service";
import { getProjectLifecycleStatus } from "@/utils/project-utils";
import ProjectLessonList from "@/components/projects/ProjectLessonList.vue";
import handleError from "@/helpers/errors";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "SellerProjectList",
  components: { ProjectLessonList },
  props: {
    sellerUID: {
      type: String as PropType<string>,
      required: true,
    }
  },
  setup(props) {
    const vm = reactive({
      loading: true,
      projects: [] as Learnlink.Project.FullViewAdmin[],
    });

    onMounted(async () => {
      try {
        vm.projects = await getAllProjectsForSeller(props.sellerUID);
      }
      catch (e) {
        handleError(e);
      }
      vm.loading = false;
    });

    return {
      getProjectLifecycleStatus,
      vm,
    };
  }
});
