import { axios } from "@/configuration";

const ENDPOINT = "/notifications";

export async function sendSMS(phone: string, message: string): Promise<void> {
  await axios.post<void>(
    `${ENDPOINT}/SMS`, {
      phone,
      message,
    }
  );
}
