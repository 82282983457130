










































import { getIntercomUserID, syncUserWithIntercom } from "@/api/intercom-service";
import { syncUserWithStripe } from "@/api/stripe-service";
import store from "@/store/store";
import { defineComponent, PropType, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "Syncers",
  props: {
    uid: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      loading: false,
    });

    async function syncWithIntercom() {
      try {
        vm.loading = true;
        await syncUserWithIntercom(props.uid);
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Skjedde noe galt! Prøv igjen eller ta med en sjokolade inn til en av de jævlig sterke Tech-gutta.",
        });
      }

      vm.loading = false;
    }

    async function syncWithStripe() {
      try {
        vm.loading = true;
        await syncUserWithStripe(props.uid);
      }
      catch (e) {
        store.commit("setError", "En feil skjedde da vi skulle synkronisere med Stripe for bruker: " + props.uid + ". Snakk med tech gutta <3");
      }

      vm.loading = false;
    }

    async function openInIntercom() {
      vm.loading = true;
      try {
        const intercomUserID = await getIntercomUserID(props.uid);
        const win = window.open(`https://app.intercom.com/a/apps/xy9br1zp/users/${intercomUserID}/all-conversations`, "_blank");
        win?.focus();
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Skjedde noe galt! Prøv igjen eller ta med en sjokolade inn til en av de jævlig sterke Tech-gutta.",
        });
      }
      vm.loading = false;
    }

    function openInStripe() {
      const win = window.open(`https://dashboard.stripe.com/search?query=${props.uid}`, "_blank");
      win?.focus();
    }

    return {
      openInIntercom,
      openInStripe,
      syncWithIntercom,
      syncWithStripe,
      vm,
    };
  },
});
