import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/customers";

export async function createStudent(student: Learnlink.Student.Create): Promise<Learnlink.Student.Student> {
  const studentRes = await axios.post<Learnlink.Student.Student>(
    `${ENDPOINT}/student`,
    student
  );

  return studentRes.data;
}

export async function getCustomer(uid: string): Promise<Learnlink.Customer.default> {
  const studentRes = await axios.get<Learnlink.Customer.default>(
    `${ENDPOINT}/${uid}`
  );
  return studentRes.data;
}

export async function getCustomers(path = "list"): Promise<Learnlink.Customer.default[]> {
  const customersRes = await axios.get<Learnlink.Customer.default[]>(
    `${ENDPOINT}/${path}`
  );
  return customersRes.data;
}

export async function getStudent(uid: string): Promise<Learnlink.Student.Student> {
  const studentRes = await axios.get<Learnlink.Student.Student>(
    `${ENDPOINT}/student/${uid}`
  );
  return studentRes.data;
}

export async function invoiceNegativeBalance(
  customer: Learnlink.Customer.default,
  employee: Learnlink.Profile.default
): Promise<void> {
  await axios.post(`${ENDPOINT}/invoiceNegativeBalance/${customer.account?.uid}/${customer.stripeCustomerAccount?.id}`, {
    employeeInitials: employee.firstName.charAt(0) + employee.lastName.charAt(0),
    employeeUID: employee.uid,
  });
}

export async function search(query: string): Promise<Learnlink.Customer.AdminSearchResults> {
  const customersRes = await axios.get<Learnlink.Customer.AdminSearchResults>(`${ENDPOINT}/search/${query}`);
  return customersRes.data;
}

export async function transferNegativeBalanceToInvoiceItem(
  customer: Learnlink.Customer.default,
  employee: Learnlink.Profile.default
): Promise<void> {
  await axios.post(`${ENDPOINT}/transferNegativeBalanceToInvoiceItem/${customer.account?.uid}`, {
    employeeInitials: employee.firstName.charAt(0) + employee.lastName.charAt(0),
    employeeUID: employee.uid,
  });
}
