import _Axios, { AxiosError, AxiosResponse } from "axios";
import * as _Sentry from "@sentry/browser";
import { auth } from "@/services/firebase";
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";

Vue.use(VueCompositionAPI);

const axiosInstance = _Axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 60 * 1000, // Timeout
  withCredentials: false, // Check cross-site Access-Control
});

axiosInstance.interceptors.request.use(
  async config => {
    // Do something before request is sent
    const requestId = Math.random().toString(36).substr(2, 15);
    sentry.configureScope(scope => {
      scope.setTag("request_id", requestId);
    });
    config.headers["x-request-id"] = requestId;

    config.baseURL = process.env.VUE_APP_API2;

    const token = await auth.currentUser?.getIdToken();
    config.headers.Authorization = "Bearer " + token;
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use((response: AxiosResponse) => {
  // Do something with response data
  return response;
},
(error: AxiosError) => {
  // Do something with response error
  return Promise.reject(error);
});

export const API = process.env.VUE_APP_API;
export const axios = axiosInstance;
export const environment = process.env.VUE_APP_FIREBASE_PROJECT_ID;
export const firebase = window.firebase;
export const sentry = _Sentry;
