





















































import { postOverrideRoomURL } from "@/api/team-service";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "OverrideRoomURL",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      password: "",
      url: "",
      username: ""
    };
  },
  mounted() {
    this.password = this.team.roomPassword || "";
    this.url = this.team.overrideRoomURL || "";
    this.username = this.team.roomUsername || "";
  },
  methods: {
    async submit() {
      this.loading = true;

      if (this.url.includes("https://learnlink-no.zoom.us") || this.url.includes("https://zoom.us")) {
        try {
          await postOverrideRoomURL(this.team.ID, this.url, this.username, this.password);
          await store.dispatch("displayAlert", {
            color: "success",
            message: "Lenken har blitt oppdatert!",
          });
          this.$emit("success");
        }
        catch (e) {
          handleError(e);
          await store.dispatch("displayAlert", {
            color: "error",
            message: "Det skjedde noe galt! Lenken ble ikke oppdatert!",
          });
        }
      }
      else if (!this.url) {
        await postOverrideRoomURL(this.team.ID, "", "", "");
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Lenken har blitt satt til et Learnlink-rom",
        });
        this.$emit("success");
      }
      else {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Det var et problem med lenken. Har du sjekket at den er skrevet riktig?",
        });
      }
    }
  },
});
