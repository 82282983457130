export function capitalize(str: string): string {
  return str[0].toUpperCase() + str.substr(1);
}

export function createLinks(content: string): string {
  const URLmatch = /(?:(?:https?|http?):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/igm;
  const withLinks = content.replace(URLmatch, match => `<a target="_blank" href="${match}">${match}</a>`);
  return withLinks;
}

export const ratingSymbols = ["😡", "🙁", "😐", "😊", "🤩"];
export const levelSymbols = ["😕", "🙂", "🥳"];
