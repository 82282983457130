import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm._value,"fullscreen":"","width":"600"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm._value = false}}},[_c(VBtn,{staticStyle:{"position":"fixed","right":"10px","z-index":"100"},attrs:{"icon":"","x-large":""},on:{"click":function($event){_vm._value = false}}},[_c(VIcon,[_vm._v("mdi-close-circle")])],1),_c(VCard,[_c(VBtn,{staticStyle:{"position":"fixed","right":"10px","z-index":"100"},attrs:{"icon":"","x-large":""},on:{"click":function($event){_vm._value = false}}},[_c(VIcon,[_vm._v("mdi-close-circle")])],1),(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":""}})],1):_c('div',[_c('edit-customer-card',{attrs:{"uid":_vm.uid,"teams":_vm.myTeams}}),_c('div',{staticClass:"text-center"},[_c('h2',{staticClass:"mb-2"},[_vm._v(" Teams ")])]),(_vm.myTeams.length)?_c(VRow,{attrs:{"justify":"center"}},_vm._l((_vm.myTeams),function(team){return _c(VCol,{key:team.ID,attrs:{"cols":"12","md":"4","lg":"3"}},[_c('team-thumbnail',{staticClass:"mb-2",attrs:{"team":team},on:{"close":function($event){_vm._value = false}}})],1)}),1):_c(VAlert,{staticStyle:{"max-width":"400px","margin":"auto"},attrs:{"type":"info","text":"","shaped":""}},[_vm._v(" Ingen 🥲 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }