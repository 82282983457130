import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/lessons";

export async function cancelLesson(lessonID: string): Promise<void> {
  await axios.delete<void>(
    `${ENDPOINT}/${lessonID}`,
  );
}

export async function updateLesson(lesson: Learnlink.Lesson.Update): Promise<Learnlink.Lesson.default> {
  const lessonRes = await axios.put<Learnlink.Lesson.default>(
    `${ENDPOINT}/${lesson.ID}`,
    lesson,
  );

  return lessonRes.data;
}

export async function getAllLessonsForProject(projectID: string): Promise<Learnlink.Lesson.FullViewAdmin[]> {
  const lessonsRes = await axios.get<Learnlink.Lesson.FullViewAdmin[]>(
    `${ENDPOINT}/allLessonsForProject/${projectID}`
  );

  return lessonsRes.data;
}

export async function getAllLessonsForUser(uid: string, usertype: Learnlink.Type.Role): Promise<Learnlink.Lesson.FullViewAdmin[]> {
  const lessonsRes = await axios.get<Learnlink.Lesson.FullViewAdmin[]>(
    `${ENDPOINT}/allLessonsForUser/${usertype}/${uid}`
  );

  return lessonsRes.data;
}
