







































import handleError from "@/helpers/errors";
import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, PropType, reactive } from "@vue/composition-api";
import { sendSMS } from "@/api/notification-service";

export default defineComponent({
  name: "SendSMS",
  props: {
    customerOrSeller: {
      type: Object as PropType<Learnlink.Seller.default | Learnlink.Customer.default>,
      required: true,
    }
  },
  setup(props, { emit }) {
    const vm = reactive({
      loading: false,
      text: "",
    });

    async function send() {
      vm.loading = true;

      try {
        await sendSMS(props.customerOrSeller.account?.phone || "", vm.text);
        store.dispatch("displayAlert", {
          color: "green",
          message: "Sendt!",
        });
        emit("success");
        vm.text = "";
      }
      catch (e) {
        handleError(e);
      }

      vm.loading = false;
    }

    return {
      send,
      vm,
    };
  }
});
