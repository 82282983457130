



















































































































import { defineComponent, inject, PropType } from "@vue/composition-api";
import { getReadableStartTime } from "@/utils/timeUtils";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "TeamThumbnail",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.Admin.Thumbnail & {
        verboseSellers: Array<{
          uid: string,
          firstName: string,
          lastName: string
        }>
      }>,
      required: true,
    },
  },
  setup(props) {
    const check = inject<(teamID: string) => void>("check");
    const uncheck = inject<(teamID: string) => void>("uncheck");

    const openTeamDialog = inject<(teamID: string) => void>("openTeamDialog");

    function getAvailableSpots(team: Learnlink.Team.Team) {
      return team.size - (team.parents ? team.parents.length : 0);
    }

    function getNumberOfSellers(): number {
      return props.team.sellers?.length;
    }

    function update(val: boolean) {
      if (val && check) check(props.team.ID);
      else if (uncheck) uncheck(props.team.ID);
    }

    return {
      getAvailableSpots,
      getNumberOfSellers,
      getReadableStartTime,
      openTeamDialog,
      update,
    };
  },
});
