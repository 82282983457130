import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.account.uid)?_c(VBottomNavigation,{attrs:{"grow":"","app":"","dark":"","fixed":"","horizontal":_vm.$vuetify.breakpoint.smAndUp,"background-color":"primary"},model:{value:(_vm.menuItemActive),callback:function ($$v) {_vm.menuItemActive=$$v},expression:"menuItemActive"}},[_vm._l((_vm.menuItems),function(item){return _c(VBtn,{key:item.path,attrs:{"color":_vm.menuItemActive === item.path ? '#a569ff' : 'menu',"value":item.path}},[_c('span',[_vm._v(_vm._s(item.title))]),_c(VIcon,[_vm._v(_vm._s(item.icon))])],1)}),_c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"menu","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticStyle:{"color":"grey !important"}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,false,3290967710)},[_c(VList,[_c(VListItem,[_c(VIcon,{staticStyle:{"position":"absolute","right":"25px"}},[_vm._v(" mdi-close ")])],1),_c(VListItem,{staticClass:"my-0"},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-theme-light-dark")])],1),_c(VListItemAction,[_c(VSwitch,{attrs:{"inset":""},model:{value:(_vm.$vuetify.theme.dark),callback:function ($$v) {_vm.$set(_vm.$vuetify.theme, "dark", $$v)},expression:"$vuetify.theme.dark"}})],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.signOut()}}},[_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"text-align":"center","border":"2px solid grey","border-radius":"5px","padding":".5em"}},[_vm._v(" Logg ut ")])],1)],1)],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }