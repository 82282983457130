


















































import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, inject, PropType, reactive, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "RemoveParentFromTeamDialog",
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    uid: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const churnUser = inject<(userType: Learnlink.Type.Role, customerUID: string) => void>("churnUserFromTeam");
    const removeUser = inject<(userType: Learnlink.Type.Role, customerUID: string) => void>("removeUserFromTeam");

    const vm = reactive({
      loading: false,
    });

    async function churn() {
      vm.loading = true;
      try {
        if (churnUser) await churnUser("parent", props.uid);
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Churnet 😭",
        });
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt - kunne ikke churne kunden",
        });
      }
      vm.loading = false;
      _value.value = false;
    }

    async function remove() {
      vm.loading = true;
      try {
        if (removeUser) await removeUser("parent", props.uid);
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Fjernet 🙌",
        });
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt - kunne ikke fjerne kunden",
        });
      }
      vm.loading = false;
      _value.value = false;
    }

    return {
      _value,
      churn,
      remove,
      vm,
    };
  },
});
