import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(_vm.isLoading)?_c(VRow,{attrs:{"justify":"center"}},[_c(VProgressCircular,{staticClass:"ma-16",attrs:{"color":"primary","indeterminate":""}})],1):(_vm.files.length)?_c(VRow,_vm._l((_vm.files),function(file){return _c(VCol,{key:file.uid},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(file.name)+" ")]),_c(VImg,{attrs:{"src":file.url}})],1)],1)}),1):_c(VAlert,{attrs:{"color":"warning","text":"","icon":"mdi-alert-circle"}},[_vm._v(" Fant ingen filer ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }