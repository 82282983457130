














































































import { auth } from "@/services/firebase";
import store from "@/store/store";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      loading: false,
      login: {
        email: "",
        password: "",
      },
      resetEmail: "",
      resetDialog: false,
      resetStep: 0,
      errorTitle: "",
      errorMessage: "",
    };
  },
  methods: {
    async signInUsingEmail() {
      this.loading = true;
      try {
        await auth.signInWithEmailAndPassword(this.login.email, this.login.password);
      }
      catch (e) {
        this.handleLoginError(e);
      }
      this.loading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleLoginError(error: any) {
      if (error.code === "auth/wrong-password") {
        store.dispatch("displayAlert", {
          title: "Feil passord",
          message: "Du kan få nytt passord ved å klikke på \"Glemt passord\"",
          color: "red",
        });
      }
      else if (error.code === "auth/user-not-found") {
        store.dispatch("displayAlert", {
          title: "Fant ikke brukeren",
          message: "Send melding til en Tech-bro for hjelp :D",
          color: "red",
        });
      }
      else if (error.code === "auth/network-request-failed") {
        store.dispatch("displayAlert", {
          title: "Wops!",
          message: "Det ser ut til at internett-tilkoblingen din er borte. Det kan hjelpe å starte nettleseren på nytt.",
          color: "red",
        });
      }
      else {
        store.dispatch("displayAlert", {
          title: "Oops",
          message: error.message,
          color: "red",
        });
      }
    },
  },
});
