import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):_c('div',[_c(VExpansionPanels,{staticClass:"mb-6",attrs:{"flat":""}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_vm._v(" Send ny beskjed ")]),_c(VExpansionPanelContent,[_c(VTextarea,{staticClass:"mb-2",attrs:{"outlined":"","hide-details":"","label":"Informasjon"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Send! ")])],1)],1)],1),_c('div',{staticStyle:{"height":"300px","overflow-y":"scroll"}},[(!_vm.team.information)?_c(VAlert,[_vm._v(" Ingen meldinger ")]):_vm._l((_vm.team.information.filter(function (i) { return !i.deleted; }).slice().reverse()),function(information){return _c(VCard,{key:information.ID},[_c(VCardTitle,[_vm._v(" "+_vm._s(information.title)+" "),(information.deleted)?_c('span',{staticStyle:{"color":"red"},attrs:{"text-align":"right"}},[_vm._v(" (Slettet) ")]):_vm._e()]),_c(VCardSubtitle,[_c('b',[_vm._v(_vm._s(_vm.getByline(information.created, information.author)))])]),_c(VCardText,{staticStyle:{"white-space":"pre-line"}},[_c(VRow,[_c(VCol,[_vm._v(" "+_vm._s(information.text)+" ")]),_c(VCol,{staticStyle:{"text-align":"right"},attrs:{"cols":"2"}},[_c(VBtn,{attrs:{"x-small":"","icon":"","color":"red"},on:{"click":function($event){return _vm.deleteInformation(information.ID)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)],1)],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }