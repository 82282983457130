
















































import { Decision } from "@/utils/timeUtils";
import { defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";

export default defineComponent({
  name: "StudentListThumbnail",
  props: {
    hasTeam: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    student: {
      type: Object as PropType<Learnlink.Student.View.Admin.Thumbnail>,
      required: true,
    },
  },
  setup() {
    const vm = reactive({
      checked: false,
    });

    const openEditCustomerDialog = inject<(customerUID: string) => void>("openEditCustomerDialog");

    function readableAnswer(answer: "no" | "yes" | "uncertain") {
      return Decision[answer];
    }
    function readableTime(timeStamp: number): string {
      return timeStamp ? ": " + Moment.unix(timeStamp).format("dddd HH:mm") : "";
    }
    function showCustomer(uid: string) {
      if (openEditCustomerDialog) openEditCustomerDialog(uid);
    }

    return {
      readableAnswer,
      readableTime,
      showCustomer,
      vm,
    };
  }
});
