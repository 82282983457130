


















































































































































import { defineComponent, inject, onMounted, PropType, reactive } from "@vue/composition-api";
import { stringTimeToUnix } from "@/utils/timeUtils";
import { updateTeamSession, deleteTeamSession, changeAllTeamSessionTimes } from "@/api/team-session-service";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";
import store from "@/store/store";
import handleError from "@/helpers/errors";

export default defineComponent({
  name: "EditTeamSession",
  props: {
    session: {
      type: Object as PropType<Learnlink.TeamSession.default>,
      required: true
    }
  },
  setup(props) {
    const vm = reactive({
      date: "",
      endTime: "",
      loading: false,
      startTime: "",
      automaticSMS: "",
    });

    const mountTeamSessions = inject<() => Promise<void>>("mountTeamSessions");
    const mountTeam = inject("mount") as () => Promise<void>;

    onMounted(() => {
      vm.date = Moment.unix(props.session.startTime).format("YYYY-MM-DD");
      vm.endTime = Moment.unix(props.session.endTime).format("HH:mm");
      vm.startTime = Moment.unix(props.session.startTime).format("HH:mm");
      vm.automaticSMS = props.session.AutomaticSMS ?? "";
    });

    function allowedEndTimeHours(value: number) {
      if (vm.startTime) return value >= +vm.startTime.split(":")[0];
      return value > 0;
    }

    function allowedEndTimeMinutes(value: number): boolean {
      return value % 5 === 0;
    }

    function allowedStartTimeMinutes(value: number): boolean {
      return value % 5 === 0;
    }

    async function update() {
      vm.loading = true;

      await updateTeamSession({
        ID: props.session.ID,
        endTime: stringTimeToUnix(vm.date, vm.endTime),
        startTime: stringTimeToUnix(vm.date, vm.startTime),
        AutomaticSMS: vm.automaticSMS,
      });

      store.dispatch("displayAlert", {
        title: "Oppdatert!",
        message: "Legende.",
        color: "green",
      });

      vm.loading = false;

      if (mountTeamSessions) await mountTeamSessions();
    }

    async function updateAllSessionTimes() {
      vm.loading = true;
      const newStartTime = stringTimeToUnix(vm.date, vm.startTime);
      const newEndTime = stringTimeToUnix(vm.date, vm.endTime);
      const startTimeDifference = newStartTime - props.session.startTime;
      const endTimeDifference = newEndTime - props.session.endTime;
      if (startTimeDifference !== 0 || endTimeDifference !== 0) {
        try {
          await changeAllTeamSessionTimes(props.session.teamID, startTimeDifference, endTimeDifference);

          await store.dispatch("displayAlert", {
            color: "success",
            message: "Alle session-tider har blitt oppdatert 🤝"
          });

          mountTeamSessions && await mountTeamSessions();
          mountTeam && await mountTeam();
        }
        catch (e) {
          handleError(e);
          await store.dispatch("displayAlert", {
            color: "error",
            message: "Klarte ikke oppdatere tidene"
          });
        }
      }
      else {
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Du har valgt samme tid som før"
        });
      }
      vm.loading = false;
    }

    async function deleteSession() {
      vm.loading = true;
      try {
        await deleteTeamSession(props.session.ID);

        await store.dispatch("displayAlert", {
          color: "success",
          message: "Team sessionen ble slettet"
        });
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Klarte ikke å slette sessionen"
        });
      }

      vm.loading = false;

      if (mountTeamSessions) await mountTeamSessions();
    }

    return {
      allowedEndTimeHours,
      allowedEndTimeMinutes,
      allowedStartTimeMinutes,
      deleteSession,
      update,
      updateAllSessionTimes,
      vm
    };
  },
});
