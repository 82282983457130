










































































































































import DocumentPropTextFieldEditor from "@/components/projects/ProjectDialog/DocumentPropTextFieldEditor.vue";
import DocumentFieldPicker from "@/components/projects/ProjectDialog/DocumentFieldPicker.vue";
import { PropType } from "@vue/composition-api";
import Vue from "vue";
import Learnlink from "@learnlink/interfaces";

export default Vue.extend({
  name: "EditCustomerForm",
  components: { DocumentFieldPicker, DocumentPropTextFieldEditor },
  props: {
    customer: {
      type: Object as PropType<Learnlink.Customer.default>,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
});
